/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { CometChatUIKit } from "../../Shared/CometChatUIKit/CometChatUIKit";

function Hooks(
    loggedInUser: any,
	setLoggedInUser: Function,
    call: any,
    setTemplates: Function
) {
    useEffect(
        () => {
            CometChatUIKit.getLoggedinUser().then(
                (user) => {
                    setLoggedInUser(user);
                }
            );
        },
        [setLoggedInUser]
    );

    useEffect(()=>{
        if(loggedInUser){
            setTemplates?.()
        }
    }, [loggedInUser, call]);
}

export { Hooks };