import { CometChatTheme, fontHelper } from "@cometchat/uikit-resources";

import { CallLogDetailsStyle } from "@cometchat/uikit-shared";
import { ListItemStyle } from "@cometchat/uikit-elements";

export function getProfileContainerStyle(): React.CSSProperties {
    return {
        height: "auto",
        width: "100%",
        minHeight: "150px",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        gap: "8px",
    }
}

export function getButtonContainerStyle(): React.CSSProperties {
    return {
        position: "absolute",
        top: "15px",
        left: "15px",
        display: "flex",
        gap: "8px",
        justifyContent: "center",
    };
}

export function getBackButtonStyle(theme: CometChatTheme, style: CallLogDetailsStyle) {
    return {
        height: "24px",
        width: "24px",
        border: "none",
        borderRadius: "0",
        background: "transparent",
        buttonIconTint: style?.backIconTint || theme.palette.getPrimary(),
    };
}

export function getContainerStyle(theme: CometChatTheme, style: CallLogDetailsStyle): React.CSSProperties {
    return {
        height: style?.height || "100%",
        width: style?.width || "100%",
        background: style?.background || theme.palette.getBackground(),
        borderRadius: style?.borderRadius || "0",
        border: style?.border || "none",
        position: "relative",
    };
}

export function getTitleStyle(theme: CometChatTheme, style: CallLogDetailsStyle) {
  return {
    font: style?.titleFont || fontHelper(theme.typography.text1),
    color: style?.titleColor || theme.palette.getAccent(),
    background: "transparent",
  };
}

export function getListItemStyle(theme: CometChatTheme, style: any){
    const { backgroundColor, titleColor, titleFont } = style;
    return new ListItemStyle({
        background: backgroundColor || "transparent",
        activeBackground: backgroundColor || "transparent",
        border: "none",
        hoverBackground: backgroundColor || "transparent",
        titleColor: titleColor || theme.palette.getAccent(),
        borderRadius: "0",
        titleFont: titleFont || fontHelper(theme.typography.text1),
        separatorColor: theme.palette.getAccent200()
    })
}