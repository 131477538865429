import { CometChat } from "@cometchat/chat-sdk-javascript";
import {
  CometChatTheme,
  CometChatUIKitConstants,
  CometChatMessageEvents,
  CometChatUIEvents,
  IActiveChatChanged,
  PanelAlignment,
  CometChatMessageComposerAction,
  localize,
  CometChatActionsView,
} from "@cometchat/uikit-resources";
import { DataSource } from "../../Shared/Framework/DataSource";
import { DataSourceDecorator } from "../../Shared/Framework/DataSourceDecorator";
import AIConversationSummaryView from "./AIConversationSummaryView";
import { AIConversationSummaryConfiguration, AIOptionsStyle } from "@cometchat/uikit-shared";

export class AIConversationSummaryDecorator extends DataSourceDecorator {
  public configuration?: AIConversationSummaryConfiguration;
  public newDataSource!: DataSource;
  public currentMessage: CometChat.BaseMessage | null = null;
  public unreadMessageCount: number = 0;
  public loggedInUser!: CometChat.User | null;
  public user!: CometChat.User;
  public group!: CometChat.Group;
  public theme: CometChatTheme = new CometChatTheme({});
  private LISTENER_ID: string = "aiconversationsummary__listener";

  constructor(
    dataSource: DataSource,
    configuration?: AIConversationSummaryConfiguration
  ) {
    super(dataSource);
    this.newDataSource = dataSource;
    this.configuration = configuration!;
    setTimeout(() => {
      this.addMessageListener();
    }, 1000);
  }

  override getId(): string {
    return "aiconversationsummary";
  }

  closePanel = () => {
    CometChatUIEvents.ccHidePanel.next(PanelAlignment.messageListFooter);
  };

  getConversationSummary = (theme?: CometChatTheme): Promise<string> => {
    this.theme = theme ?? new CometChatTheme({});
    return new Promise(async (resolve, reject) => {
      try {
        let receiverId: string = this.user
          ? this.user?.getUid()
          : this.group?.getGuid();
        let receiverType: string = this.user
          ? CometChatUIKitConstants.MessageReceiverType.user
          : CometChatUIKitConstants.MessageReceiverType.group;
        let configuration;
        if (this.configuration?.apiConfiguration) {
          configuration = await this.configuration?.apiConfiguration(
            this.user,
            this.group
          );
        }
        const response = await CometChat.getConversationSummary(
          receiverId,
          receiverType,
          configuration ? configuration : {}
        );
        return resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  };

  private loadConversationSummary(): void {
    CometChatUIEvents.ccShowPanel.next({ configuration: this.configuration, message: this.currentMessage!, child: <AIConversationSummaryView configuration={this.configuration} getConversationSummaryCallback={this.getConversationSummary} closeCallback={this.closePanel} />, position: PanelAlignment.messageListFooter });
  }

  override getAIOptions(user: CometChat.User | null, group: CometChat.Group | null, theme: CometChatTheme, id?: any, aiOptionsStyle?: AIOptionsStyle): (CometChatMessageComposerAction | CometChatActionsView)[] {
    this.user = user!;
    this.group = group!;
    if (!id?.parentMessageId) {
      let style;
      if (aiOptionsStyle) {
        style = {
          titleColor: this.configuration?.conversationSummaryStyle?.buttonTextColor || aiOptionsStyle?.listItemTextColor,
          titleFont: this.configuration?.conversationSummaryStyle?.buttonTextFont || aiOptionsStyle?.listItemTextFont,
          background: this.configuration?.conversationSummaryStyle?.buttonBackground || aiOptionsStyle?.listItemBackground
        }
      }
      const messageComposerActions: (CometChatMessageComposerAction | CometChatActionsView)[] = super.getAIOptions(user, group, theme, id, aiOptionsStyle);
      let newAction: CometChatMessageComposerAction = new CometChatMessageComposerAction({
        title: localize("CONVERSATION_SUMMARY"),
        onClick: () => { this.loadConversationSummary() },
        id: "ai-conversation-summary",
        iconURL: '',
        iconTint: '',
        titleColor: style?.titleColor,
        titleFont: style?.titleFont,
        background: style?.background,
      });
      messageComposerActions.push(newAction);
      return messageComposerActions;
    } else {
      return super.getAIOptions(user, group, theme, id, aiOptionsStyle);
    }
  }

  private addMessageListener(): void {
    CometChat.getLoggedinUser().then((user: CometChat.User | null) => {
      if (user) {
        this.loggedInUser = user;
      }
    });

    CometChatUIEvents.ccActiveChatChanged.subscribe(
      (data: IActiveChatChanged) => {
        this.currentMessage = data.message!;
        this.user = data.user!;
        this.group = data.group!;
        this.unreadMessageCount = data.unreadMessageCount ?? 0;
        if (this.unreadMessageCount > (this.configuration?.unreadMessageThreshold ?? 30)) {
          this.loadConversationSummary();
        }
      }
    );
  }
}