import { CometChatTheme, fontHelper } from "@cometchat/uikit-resources";
import { LabelStyle } from "@cometchat/uikit-elements";
import { WithDetailsStyle } from "@cometchat/uikit-shared";

export function getContainerStyle(
  theme: CometChatTheme,
  style: WithDetailsStyle
): React.CSSProperties {
  return {
    height: style?.height || "100%",
    width: style?.width || "100%",
    border: style?.border || `none`,
    borderRadius: style?.borderRadius || "0px",
    background: style?.background || theme.palette.getBackground(),
    display: "flex",
    boxSizing: "border-box",
    position: "relative",
  };
}

export function getLabelStyle(theme: CometChatTheme, style: WithDetailsStyle) {
  return new LabelStyle({
    background: "transparent",
    textFont: style?.messageTextFont || fontHelper(theme.typography.heading),
    textColor: style?.messageTextColor || theme.palette.getAccent700(),
  });
}

export function getEmptyContainerStyle() {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  };
}
