import {
  CometChatTheme,
  MessageBubbleAlignment,
} from "@cometchat/uikit-resources";

import { CSSProperties } from "react";

export const MessageBubbleWrapperStyles = (
  alignment: MessageBubbleAlignment,
  MessageBubbleAlignment: any
) => {
  return {
    display: "flex",
    position: "relative",
    maxWidth: "65%",
    width: "fit-content",
    gap: '4px'
  } as CSSProperties;
};

export const MessageBubbleAvatarStyles = () => {
  return {
    position: "relative",
    borderRadius: "inherit",
    display: "flex",
    width: "fit-content",
    alignSelf: "flex-start",
    marginTop: "5px",
    padding: "8px 4px",
  } as CSSProperties;
};

export const MessageBubbleAlignmentStyles = (
  alignment: MessageBubbleAlignment,
  MessageBubbleAlignment: any
) => {
  return {
    flex: "1 1 0",
    display: "flex",
    flexDirection: "column",
    borderRadius: "inherit",
    justifyContent:
      alignment === MessageBubbleAlignment.left ? "flex-start" : "flex-end",
    alignItems:
      alignment === MessageBubbleAlignment.left ? "flex-start" : "flex-end",
    position: "relative",
    width: "100%"
  } as CSSProperties;
};

export const MessageBubbleTitleStyles = (
  alignment: MessageBubbleAlignment,
  MessageBubbleAlignment: any
) => {
  return {
    display: "flex",
    justifyContent:
      alignment === MessageBubbleAlignment.left ? "flex-start" : "flex-end",
    alignItems: "flex-start",
  };
};

export const MessageOptionsStyles = (
  alignment: MessageBubbleAlignment,
  MessageBubbleAlignment: any,
  headerView: any,
  theme: CometChatTheme
) => {
  return {
    justifyContent:
      alignment === MessageBubbleAlignment.left ? "flex-start" : "flex-end",
    top: headerView ? "-17px" : "-36px",
    right: alignment === MessageBubbleAlignment.right ? "unset" : "0",
    left: alignment === MessageBubbleAlignment.left ? "0" : "unset",
    position: "absolute",
    background: theme.palette.getBackground(),
    borderRadius: "8px",
    width: "fit-content",
    zIndex: "2",
  } as CSSProperties;
};

export const menuListStyles = (theme: CometChatTheme) => {
  return {
    border: `1px solid ${theme.palette.getAccent200()}`,
    borderRadius: "8px",
    background: theme.palette.getBackground(),
    submenuWidth: "100%",
    submenuHeight: "100%",
    submenuBorder: `1px solid ${theme.palette.getAccent200()}`,
    submenuBorderRadius: "8px",
    submenuBackground: theme.palette.getBackground(),
    moreIconTint: theme.palette.getAccent600(),
  };
};
