import { CometChat } from "@cometchat/chat-sdk-javascript";
import React, { useEffect } from "react";
import { CometChatUserEvents } from "@cometchat/uikit-resources";
import { Action } from ".";
import { UsersManager } from "./controller";

type Args = {
    usersManagerRef : React.MutableRefObject<UsersManager | null>,
    fetchNextAndAppendUsers : (fetchId : string) => Promise<void>,
    searchText : string,
    usersRequestBuilder : CometChat.UsersRequestBuilder | null,
    searchRequestBuilder : CometChat.UsersRequestBuilder | null,
    dispatch : React.Dispatch<Action>,
    updateUser : (user : CometChat.User) => void,
    fetchNextIdRef : React.MutableRefObject<string>,
    searchKeyword: string,
    disableLoadingState: boolean,
    usersSearchText:React.MutableRefObject<string>,
};

export function Hooks(args : Args) {
    const {
        usersManagerRef,
        fetchNextAndAppendUsers,
        searchText,
        usersRequestBuilder,
        searchRequestBuilder,
        dispatch,
        updateUser,
        fetchNextIdRef,
        searchKeyword,
        disableLoadingState,
        usersSearchText,
    } = args;



    
    useEffect(() => {
        if(usersRequestBuilder?.searchKeyword){
            usersSearchText.current = usersRequestBuilder?.searchKeyword;
        }else if(searchRequestBuilder?.searchKeyword){
            usersSearchText.current = searchRequestBuilder?.searchKeyword;
        }
        return  ()=> {
            /* 
               When the prop (usersRequestBuilder) gets updated (setSearchKeyword), reference in parent component gets updated too. 
               This was causing an issue in mentions since the previous search keyword remained in the request builder reference in 
               composer.
            */
          usersRequestBuilder?.setSearchKeyword("")
          }
    }, []);   
    useEffect(
        /**
         * Creates a new request builder -> empties the `userList` state -> initiates a new fetch
         */
        () => {
            dispatch({ type: "setIsFirstReload", isFirstReload: true });
            usersManagerRef.current = new UsersManager({searchText, usersRequestBuilder, searchRequestBuilder,usersSearchText});
            if(!disableLoadingState){
            dispatch({type: "setUserList", userList: []});
            }
            fetchNextAndAppendUsers(fetchNextIdRef.current = "initialFetch_" + String(Date.now()));
    }, [searchText, usersRequestBuilder, searchRequestBuilder, fetchNextAndAppendUsers, dispatch, fetchNextIdRef, usersManagerRef]);

    useEffect(
        /**
         * Attaches an SDK user listener
         *
         * @returns - Function to remove the added SDK user listener
         */
        () => {
            return UsersManager.atttachListeners(updateUser);
    }, [updateUser]);

    useEffect(
        /**
         * Subscribes to User UI events
         */
        () => {
            const subUserBlocked = CometChatUserEvents.ccUserBlocked.subscribe(updateUser);
            const subUserUnblocked = CometChatUserEvents.ccUserUnblocked.subscribe(updateUser);
            return () => {
                subUserBlocked.unsubscribe();
                subUserUnblocked.unsubscribe();
            };
    }, [updateUser]);

    useEffect(
        () => {
        dispatch({type: "setSearchText", searchText: searchKeyword});
    }, [searchKeyword , dispatch]);
}
