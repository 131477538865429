import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatTheme, fontHelper } from '@cometchat/uikit-resources';
import { WithMessagesStyle, JoinGroupConfiguration, MessageHeaderStyle, MessageHeaderConfiguration, CreateGroupConfiguration, GroupsStyle, GroupsConfiguration, MessagesConfiguration, MessagesStyle, BaseStyle } from '@cometchat/uikit-shared';
import { LabelStyle, JoinGroupStyle, ListItemStyle, CreateGroupStyle, BackdropStyle } from '@cometchat/uikit-elements';
import { CSSProperties } from 'react';

const labelStyle: LabelStyle = {
    height: '100%',
    width: '100%',
    background: "transparent",
    border: "none",
    borderRadius: "0",
    textFont: "700 22px Inter, sans-serif",
    textColor: "rgba(20, 20, 20, 0.33)",
};

const WithMessagesWrapperStyle = {
    display: 'flex',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative'
} as CSSProperties;

const WithMessagesMainStyle = {
    width: 'calc(100% - 280px)',
    height: '100%'
} as CSSProperties;

const MobileLayoutStyle = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0'
} as CSSProperties;

const EmptyMessagesDivStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'inherit',
    border: 'none',
    borderRadius: 'inherit',
    width: '100%',
    height: 'auto'
} as CSSProperties;


const WithMessagesSidebarStyle = {
    width: '280px',
    height: '100%',
    position: 'relative',
} as CSSProperties;

export const getGroupsWrapperStyles = (groupsWithMessagesStyle: WithMessagesStyle | undefined, theme: CometChatTheme) => {
    return {
        ...WithMessagesWrapperStyle,
        width: '100%',
        height: '100%',
        border: `1px solid ${theme.palette.getAccent100()}`,
        borderRadius: '0',
        background: theme.palette.getBackground(),
        ...groupsWithMessagesStyle
    } as CSSProperties
}

export const getWithMessagesSidebarStyle = (groupsWithMessagesStyle: WithMessagesStyle | undefined, theme: CometChatTheme, isMobileView: boolean | undefined, activeGroup: CometChat.Group | undefined) => {
    if (isMobileView) {
        return getMobileViewSidebarStyle(activeGroup);
    } else {
        return getDesktopViewSidebarStyle(groupsWithMessagesStyle, theme);
    }
};

const getMobileViewSidebarStyle = (activeGroup: CometChat.Group | undefined) => {

    const visibility = activeGroup ? { "visibility": "hidden" } : { "visibility": "visible" };
    return {
        ...visibility,
        ...MobileLayoutStyle,
        borderRadius: 'inherit',
    } as CSSProperties
}

const getDesktopViewSidebarStyle = (groupsWithMessagesStyle: WithMessagesStyle | undefined, theme: CometChatTheme) => {

    const borderRadius = groupsWithMessagesStyle?.borderRadius || 0;
    return {
        ...WithMessagesSidebarStyle,
        borderRight: groupsWithMessagesStyle?.border || `1px solid ${theme.palette.getAccent100()}`,
        borderRadius: `${borderRadius} 0 0 ${borderRadius}`
    } as CSSProperties
}

export const getWithMessagesMainStyle = (groupsWithMessagesStyle: WithMessagesStyle | undefined, isMobileView: boolean | undefined, activeGroup: CometChat.Group | undefined) => {
    if (isMobileView) {
        return getMobileViewMainStyle(activeGroup);
    } else {
        return getDesktopViewMainStyle(groupsWithMessagesStyle);
    }
}

const getMobileViewMainStyle = (activeGroup: CometChat.Group | undefined) => {

    const visibility = activeGroup ? { "visibility": "visible" } : { "visibility": "hidden" };
    return {
        ...visibility,
        ...MobileLayoutStyle,
        borderRadius: 'inherit',
    } as CSSProperties;
}

const getDesktopViewMainStyle = (groupsWithMessagesStyle: WithMessagesStyle | undefined) => {

    const borderRadius = groupsWithMessagesStyle?.borderRadius || 0;
    return {
        ...WithMessagesMainStyle,
        borderRadius: `0 ${borderRadius} ${borderRadius} 0`
    }
}

export const getLabelStyle = (groupsWithMessagesStyle: WithMessagesStyle | undefined, theme: CometChatTheme) => {

    return {
        ...labelStyle,
        textFont: groupsWithMessagesStyle?.messageTextFont || fontHelper(theme.typography.title1),
        textColor: groupsWithMessagesStyle?.messageTextColor || theme.palette.getAccent600(),
    } as LabelStyle;
}

export const getGroupsStyle = (groupsConfiguration: GroupsConfiguration | undefined) => {

    const groupsStyle = groupsConfiguration?.groupsStyle;
    return {
        width: "100%",
        height: "100%",
        border: "0 none",
        borderRadius: "inherit",
        background: "inherit",
        ...groupsStyle
    } as GroupsStyle;
}

export const getMessageHeaderStyle = (groupsWithMessagesStyle: WithMessagesStyle | undefined, messagesConfiguration: MessagesConfiguration | undefined, isMobileView: boolean | undefined) => {

    const borderRadius = groupsWithMessagesStyle?.borderRadius;
    return {
        borderRadius: isMobileView ? `${borderRadius} ${borderRadius} 0 0` : `0px ${borderRadius} 0px 0px`,
        ...messagesConfiguration?.messageHeaderConfiguration?.messageHeaderStyle
    } as MessageHeaderStyle;
}

export const getMessageComposerStyle = (groupsWithMessagesStyle: WithMessagesStyle | undefined, messagesConfiguration: MessagesConfiguration | undefined, isMobileView: boolean | undefined) => {

    const borderRadius = groupsWithMessagesStyle?.borderRadius;
    return {
        borderRadius: isMobileView ? `0 0 ${borderRadius} ${borderRadius}` : `0px 0 ${borderRadius} 0px`,
        ...messagesConfiguration?.messageComposerConfiguration?.messageComposerStyle
    }
}

export const getMessagesStyle = (
    messagesStyle?: MessagesStyle,
    withMessagesStyle?: WithMessagesStyle | undefined
) => {
    return { ...{ background: withMessagesStyle?.background }, ...messagesStyle }
};

export const getEmptyMessageLayoutStyle = (isMobileView: boolean | undefined, activeGroup: CometChat.Group | undefined) => {

    let visibility = { visibility: 'hidden' };
    if (!activeGroup && !isMobileView) {
        visibility = { "visibility": "visible" };
    }

    return {
        ...EmptyMessagesDivStyle,
        ...visibility,
        width: `calc(100% - 280px)`
    } as CSSProperties
}

export const getJoinGroupWrapperStyle = (isMobileView: boolean | undefined) => {

    if (isMobileView) {
        return {
            ...MobileLayoutStyle,
            background: 'inherit',
            borderRadius: 'inherit'
        }
    } else {
        return {
            width: `calc(100% - 280px)`,
            background: 'inherit',
            borderRadius: 'inherit'
        }
    }
}

export const getJoinGroupStyle = (JoinGroupConfiguration: JoinGroupConfiguration | undefined, theme: CometChatTheme) => {
    const configStyle = JoinGroupConfiguration?.joinGroupStyle;
    return {
        width: configStyle?.width || "100%",
        height: configStyle?.height || "100%",
        boxShadow: configStyle?.boxShadow || 'none',
        background: configStyle?.background || "transparent",
        border: configStyle?.border || "0",
        borderRadius: configStyle?.borderRadius || "inherit",
        titleTextFont: configStyle?.titleTextFont || fontHelper(theme.typography.heading),
        titleTextColor: configStyle?.titleTextFont || theme.palette.getAccent700(),
        passwordInputPlaceholderTextFont: configStyle?.passwordInputPlaceholderTextFont || fontHelper(theme.typography.subtitle1),
        passwordInputPlaceholderTextColor: configStyle?.passwordInputPlaceholderTextColor || theme.palette.getAccent600(),
        passwordInputBackground: configStyle?.passwordInputBackground || theme.palette.getAccent50(),
        passwordInputBorder: configStyle?.passwordInputBorder || "none",
        passwordInputBorderRadius: configStyle?.passwordInputBorderRadius || "8px",
        passwordInputBoxShadow: configStyle?.passwordInputBoxShadow || `${theme.palette.getAccent50()} 0 0 0 1px`,
        passwordInputTextFont: configStyle?.passwordInputTextFont || fontHelper(theme.typography.subtitle1),
        passwordInputTextColor: configStyle?.passwordInputTextColor || theme.palette.getAccent(),
        joinButtonTextFont: configStyle?.joinButtonTextFont || fontHelper(theme.typography.title2),
        joinButtonTextColor: configStyle?.joinButtonTextColor || theme.palette.getBackground(),
        joinButtonBackground: configStyle?.joinButtonBackground || theme.palette.getPrimary(),
        joinButtonBorderRadius: configStyle?.joinButtonBorderRadius || "8px",
        joinButtonBorder: configStyle?.joinButtonBorder || "none",
        errorTextFont: configStyle?.errorTextFont || fontHelper(theme.typography.subtitle1),
        errorTextColor: configStyle?.errorTextFont || theme.palette.getError()
    } as JoinGroupStyle;
}

export const getMessageHeaderSlotStyle = () => {
    return {
        position: 'absolute',
        top: '0',
        left: '0'
    } as CSSProperties;
}

export const getJoinGroupMessageHederStyle = (groupsWithMessagesStyle: WithMessagesStyle | undefined, messageHeaderConfiguration: MessageHeaderConfiguration | undefined, theme: CometChatTheme) => {

    const configStyle = messageHeaderConfiguration?.messageHeaderStyle;
    return {
        background: configStyle?.background || 'inherit',
        border: 'none',
        borderRadius: configStyle?.borderRadius || groupsWithMessagesStyle?.borderRadius || 'inherit',
        onlineStatusColor: configStyle?.onlineStatusColor || theme.palette.getSuccess(),
        privateGroupIconBackground: configStyle?.privateGroupIconBackground || theme.palette.getSuccess(),
        passwordGroupIconBackground: configStyle?.passwordGroupIconBackground || "RGB(247, 165, 0)",
        backButtonIconTint: configStyle?.backButtonIconTint || theme.palette.getPrimary(),
        subtitleTextColor: configStyle?.subtitleTextColor || theme.palette.getAccent600(),
        subtitleTextFont: configStyle?.subtitleTextFont || fontHelper(theme.typography.subtitle2),
        typingIndicatorTextColor: configStyle?.typingIndicatorTextColor || theme.palette.getPrimary(),
        typingIndicatorTextFont: configStyle?.typingIndicatorTextFont || fontHelper(theme.typography.subtitle1),
    } as MessageHeaderStyle;
}

export const getListItemStyle = (messageHeaderConfiguration: MessageHeaderConfiguration | undefined, theme: CometChatTheme) => {

    const configStyle = messageHeaderConfiguration?.listItemStyle;
    return {
        width: configStyle?.width || "100%",
        height: configStyle?.height || "45px",
        background: configStyle?.background || "inherit",
        hoverBackground: configStyle?.hoverBackground || "transparent",
        activeBackground: configStyle?.activeBackground || "transparent",
        borderRadius: configStyle?.borderRadius || "0",
        titleFont: configStyle?.titleFont || fontHelper(theme.typography.title2),
        titleColor: configStyle?.titleColor || theme.palette.getAccent(),
        border: configStyle?.border || "none",
        separatorColor: configStyle?.separatorColor || "",
    } as ListItemStyle;
}

export const getBackdropStyle = (groupsWithMessagesStyle: WithMessagesStyle | undefined): BackdropStyle => {
    return {
        height: groupsWithMessagesStyle?.height,
        width: groupsWithMessagesStyle?.width,
        position: 'absolute'
    } as BaseStyle;
}

export const getCreateGroupWrapperStyle = (isMobileView: boolean | undefined) => {
    if (isMobileView) {
        return {
            ...MobileLayoutStyle,
            background: 'inherit',
            borderRadius: 'inherit'
        } as CSSProperties;
    }
}

export const getCreateGroupStyle = (createGroupConfiguration: CreateGroupConfiguration | undefined, theme: CometChatTheme, isMobileView: boolean | undefined) => {

    let width = "360px",
        height = '500px';
    if (isMobileView) {

        width = '100%';
        height = '100%';
    }

    const configStyle = createGroupConfiguration?.createGroupStyle;
    return {
        width: configStyle?.width || width,
        height: configStyle?.height || height,
        border: configStyle?.border || '0 none',
        borderRadius: configStyle?.borderRadius || '0',
        background: configStyle?.background || theme.palette.getBackground(),
        boxShadow: configStyle?.boxShadow || `${theme.palette.getAccent100()} 4px 16px 32px 4px`,
        titleTextFont: configStyle?.titleTextFont || fontHelper(theme.typography.heading),
        titleTextColor: configStyle?.titleTextColor || theme.palette.getAccent(),
        groupTypeTextFont: configStyle?.groupTypeTextFont || fontHelper(theme.typography.text3),
        groupTypeBorder: configStyle?.groupTypeBorder || '0 none',
        groupTypeBorderRadius: configStyle?.groupTypeBorderRadius || "0px",
        groupTypeTextColor: configStyle?.groupTypeTextColor || theme.palette.getAccent(),
        groupTypeTextBackground: configStyle?.groupTypeTextBackground || "inherit",
        groupTypeBackground: configStyle?.groupTypeBackground || theme.palette.getAccent100(),
        groupTypeBoxShadow: configStyle?.groupTypeBoxShadow || "",
        activeGroupTypeTextFont: configStyle?.activeGroupTypeTextFont || fontHelper(theme.typography.text3),
        activeGroupTypeTextColor: configStyle?.activeGroupTypeTextColor || theme.palette.getAccent(),
        activeGroupTypeBackground: configStyle?.activeGroupTypeBackground || theme.palette.getAccent900(),
        activeGroupTypeBoxShadow: configStyle?.activeGroupTypeBoxShadow || `${theme.palette.getAccent200()} 0 3px 8px 0`,
        activeGroupTypeBorderRadius: configStyle?.activeGroupTypeBorderRadius || "8px",
        activeGroupTypeBorder: configStyle?.activeGroupTypeBorder || "none",
        groupTypeTextBoxShadow: configStyle?.groupTypeTextBoxShadow || "none",
        groupTypeTextBorderRadius: configStyle?.groupTypeTextBorderRadius || "7px",
        closeIconTint: configStyle?.closeIconTint || theme.palette.getPrimary(),
        errorTextFont: configStyle?.errorTextFont || fontHelper(theme.typography.text2),
        errorTextBackground: configStyle?.errorTextBackground || theme.palette.getError(),
        errorTextBorderRadius: configStyle?.errorTextBorderRadius || "8px",
        errorTextBorder: configStyle?.errorTextBorder || "none",
        errorTextColor: configStyle?.errorTextColor || theme.palette.getError(),
        nameInputPlaceholderTextFont: configStyle?.nameInputPlaceholderTextFont || fontHelper(theme.typography.subtitle1),
        nameInputPlaceholderTextColor: configStyle?.nameInputPlaceholderTextColor || theme.palette.getAccent600(),
        nameInputBackground: configStyle?.nameInputBackground || theme.palette.getAccent100(),
        nameInputTextFont: configStyle?.nameInputTextFont || fontHelper(theme.typography.subtitle1),
        nameInputTextColor: configStyle?.nameInputTextColor || theme.palette.getAccent(),
        nameInputBorder: configStyle?.nameInputBorder || "none",
        nameInputBorderRadius: configStyle?.nameInputBorderRadius || "8px",
        nameInputBoxShadow: configStyle?.nameInputBoxShadow || `${theme.palette.getAccent100()} 0 0 0 1px`,
        passwordInputPlaceholderTextFont: configStyle?.passwordInputPlaceholderTextFont || fontHelper(theme.typography.subtitle1),
        passwordInputPlaceholderTextColor: configStyle?.passwordInputPlaceholderTextColor || theme.palette.getAccent600(),
        passwordInputBackground: configStyle?.passwordInputBackground || theme.palette.getAccent100(),
        passwordInputBorder: configStyle?.passwordInputBorder || `none`,
        passwordInputBorderRadius: configStyle?.passwordInputBorderRadius || "8px",
        passwordInputBoxShadow: configStyle?.passwordInputBoxShadow || `${theme.palette.getAccent100()} 0 0 0 1px`,
        passwordInputTextFont: configStyle?.passwordInputTextFont || fontHelper(theme.typography.subtitle1),
        passwordInputTextColor: configStyle?.passwordInputTextColor || theme.palette.getAccent(),
        createGroupButtonTextFont: configStyle?.createGroupButtonTextFont || fontHelper(theme.typography.title2),
        createGroupButtonTextColor: configStyle?.createGroupButtonTextColor || theme.palette.getAccent("dark"),
        createGroupButtonBackground: configStyle?.createGroupButtonBackground || theme.palette.getPrimary(),
        createGroupButtonBorderRadius: configStyle?.createGroupButtonBorderRadius || "8px",
        createGroupButtonBorder: configStyle?.createGroupButtonBorder || "none",
    } as CreateGroupStyle;
}

export const createGroupButtonStyle = (theme: CometChatTheme) => {
    return {
        height: "24px",
        width: "24px",
        border: "none",
        borderRadius: '0',
        background: "transparent",
        buttonIconTint: `${theme.palette.getPrimary()}`,
        padding: '0'
    }
};
