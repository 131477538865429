import {
  BaseStyle,
  FileBubbleStyle,
  ImageBubbleStyle,
  TextBubbleStyle,
} from "@cometchat/uikit-elements";
import {
  CardBubbleStyle,
  CometChatMentionsFormatter,
  CometChatTextFormatter,
  CometChatUrlsFormatter,
  ComposerId,
  FormBubbleStyle,
  SchedulerBubbleStyle,
} from "@cometchat/uikit-shared";
import {
  CardMessage,
  CometChatActionsIcon,
  CometChatActionsView,
  CometChatDetailsTemplate,
  CometChatMessageComposerAction,
  CometChatMessageTemplate,
  CometChatTheme,
  FormMessage,
  MessageBubbleAlignment,
  SchedulerMessage,
} from "@cometchat/uikit-resources";

import { AIOptionsStyle } from "@cometchat/uikit-shared";
import { DataSource } from "./DataSource";
import { MessagesDataSource } from "../Utils/MessagesDataSource";

export abstract class DataSourceDecorator implements DataSource {
  dataSource: DataSource;
  constructor(dataSource: DataSource) {
    this.dataSource = dataSource;
  }

  getTextMessageOptions(
    loggedInUser: CometChat.User,
    messageObject: CometChat.BaseMessage,
    theme: CometChatTheme,
    group?: CometChat.Group
  ): Array<CometChatActionsIcon | CometChatActionsView> {
    return (this.dataSource ?? new MessagesDataSource()).getTextMessageOptions(
      loggedInUser,
      messageObject,
      theme,
      group
    );
  }
  getImageMessageOptions(
    loggedInUser: CometChat.User,
    messageObject: CometChat.BaseMessage,
    theme: CometChatTheme,
    group?: CometChat.Group
  ): Array<CometChatActionsIcon | CometChatActionsView> {
    return (this.dataSource ?? new MessagesDataSource()).getImageMessageOptions(
      loggedInUser,
      messageObject,
      theme,
      group
    );
  }
  getVideoMessageOptions(
    loggedInUser: CometChat.User,
    messageObject: CometChat.BaseMessage,
    theme: CometChatTheme,
    group?: CometChat.Group
  ): Array<CometChatActionsIcon | CometChatActionsView> {
    return (this.dataSource ?? new MessagesDataSource()).getVideoMessageOptions(
      loggedInUser,
      messageObject,
      theme,
      group
    );
  }
  getAudioMessageOptions(
    loggedInUser: CometChat.User,
    messageObject: CometChat.BaseMessage,
    theme: CometChatTheme,
    group?: CometChat.Group
  ): Array<CometChatActionsIcon | CometChatActionsView> {
    return (this.dataSource ?? new MessagesDataSource()).getAudioMessageOptions(
      loggedInUser,
      messageObject,
      theme,
      group
    );
  }
  getFileMessageOptions(
    loggedInUser: CometChat.User,
    messageObject: CometChat.BaseMessage,
    theme: CometChatTheme,
    group?: CometChat.Group
  ): Array<CometChatActionsIcon | CometChatActionsView> {
    return (this.dataSource ?? new MessagesDataSource()).getFileMessageOptions(
      loggedInUser,
      messageObject,
      theme,
      group
    );
  }
  getBottomView(
    message: CometChat.BaseMessage,
    alignment: MessageBubbleAlignment
  ) {
    return (this.dataSource ?? new MessagesDataSource()).getBottomView(
      message,
      alignment
    );
  }
  getTextMessageContentView(
    message: CometChat.TextMessage,
    alignment: MessageBubbleAlignment,
    theme: CometChatTheme,
    additionalConfigurations?: any
  ) {
    return (
      this.dataSource ?? new MessagesDataSource()
    ).getTextMessageContentView(
      message,
      alignment,
      theme,
      additionalConfigurations
    );
  }
  getImageMessageContentView(
    message: CometChat.MediaMessage,
    alignment: MessageBubbleAlignment,
    theme: CometChatTheme
  ) {
    return (
      this.dataSource ?? new MessagesDataSource()
    ).getImageMessageContentView(message, alignment, theme);
  }
  getVideoMessageContentView(
    message: CometChat.MediaMessage,
    alignment: MessageBubbleAlignment,
    theme: CometChatTheme
  ) {
    return (
      this.dataSource ?? new MessagesDataSource()
    ).getVideoMessageContentView(message, alignment, theme);
  }
  getAudioMessageContentView(
    message: CometChat.MediaMessage,
    alignment: MessageBubbleAlignment,
    theme: CometChatTheme
  ) {
    return (
      this.dataSource ?? new MessagesDataSource()
    ).getAudioMessageContentView(message, alignment, theme);
  }
  getFileMessageContentView(
    message: CometChat.MediaMessage,
    alignment: MessageBubbleAlignment,
    theme: CometChatTheme
  ) {
    return (
      this.dataSource ?? new MessagesDataSource()
    ).getFileMessageContentView(message, alignment, theme);
  }
  getFormMessageContentView(
    message: FormMessage,
    alignment: MessageBubbleAlignment,
    theme: CometChatTheme
  ) {
    return (
      this.dataSource ?? new MessagesDataSource()
    ).getFormMessageContentView(message, alignment, theme);
  }
  getSchedulerMessageContentView(
    message: SchedulerMessage,
    alignment: MessageBubbleAlignment,
    theme: CometChatTheme
  ) {
    return (
      this.dataSource ?? new MessagesDataSource()
    ).getSchedulerMessageContentView(message, alignment, theme);
  }
  getCardMessageContentView(
    message: CardMessage,
    alignment: MessageBubbleAlignment,
    theme: CometChatTheme
  ) {
    return (
      this.dataSource ?? new MessagesDataSource()
    ).getCardMessageContentView(message, alignment, theme);
  }
  getTextMessageTemplate(
    theme: CometChatTheme,
    additionalConfigurations?: any
  ): CometChatMessageTemplate {
    return (this.dataSource ?? new MessagesDataSource()).getTextMessageTemplate(
      theme,
      additionalConfigurations
    );
  }
  getImageMessageTemplate(theme: CometChatTheme): CometChatMessageTemplate {
    return (
      this.dataSource ?? new MessagesDataSource()
    ).getImageMessageTemplate(theme);
  }
  getVideoMessageTemplate(theme: CometChatTheme): CometChatMessageTemplate {
    return (
      this.dataSource ?? new MessagesDataSource()
    ).getVideoMessageTemplate(theme);
  }
  getAudioMessageTemplate(theme: CometChatTheme): CometChatMessageTemplate {
    return (
      this.dataSource ?? new MessagesDataSource()
    ).getAudioMessageTemplate(theme);
  }
  getFileMessageTemplate(theme: CometChatTheme): CometChatMessageTemplate {
    return (this.dataSource ?? new MessagesDataSource()).getFileMessageTemplate(
      theme
    );
  }
  getGroupActionTemplate(theme: CometChatTheme): CometChatMessageTemplate {
    return (this.dataSource ?? new MessagesDataSource()).getGroupActionTemplate(
      theme
    );
  }
  getFormMessageTemplate(theme: CometChatTheme): CometChatMessageTemplate {
    return (this.dataSource ?? new MessagesDataSource()).getFormMessageTemplate(
      theme
    );
  }
  getSchedulerMessageTemplate(theme: CometChatTheme): CometChatMessageTemplate {
    return (
      this.dataSource ?? new MessagesDataSource()
    ).getSchedulerMessageTemplate(theme);
  }
  getCardMessageTemplate(theme: CometChatTheme): CometChatMessageTemplate {
    return (this.dataSource ?? new MessagesDataSource()).getCardMessageTemplate(
      theme
    );
  }
  getAllMessageTemplates(
    theme?: CometChatTheme | undefined,
    additionalConfigurations?: any
  ): CometChatMessageTemplate[] {
    return (this.dataSource ?? new MessagesDataSource()).getAllMessageTemplates(
      theme,
      additionalConfigurations
    );
  }
  getMessageTemplate(
    messageType: string,
    messageCategory: string,
    theme?: CometChatTheme | undefined
  ): CometChatMessageTemplate | null {
    return (this.dataSource ?? new MessagesDataSource()).getMessageTemplate(
      messageType,
      messageCategory,
      theme
    );
  }
  getMessageOptions(
    loggedInUser: CometChat.User,
    messageObject: CometChat.BaseMessage,
    theme: CometChatTheme,
    group?: CometChat.Group
  ): Array<CometChatActionsIcon | CometChatActionsView> {
    return (this.dataSource ?? new MessagesDataSource()).getMessageOptions(
      loggedInUser,
      messageObject,
      theme,
      group
    );
  }
  getCommonOptions(
    loggedInUser: CometChat.User,
    messageObject: CometChat.BaseMessage,
    theme: CometChatTheme,
    group?: CometChat.Group
  ): Array<CometChatActionsIcon | CometChatActionsView> {
    return (this.dataSource ?? new MessagesDataSource()).getCommonOptions(
      loggedInUser,
      messageObject,
      theme,
      group
    );
  }
  getAttachmentOptions(
    theme: CometChatTheme,
    id: ComposerId
  ): CometChatMessageComposerAction[] {
    return (this.dataSource ?? new MessagesDataSource()).getAttachmentOptions(
      theme,
      id
    );
  }
  getAllMessageTypes(): string[] {
    return (this.dataSource ?? new MessagesDataSource()).getAllMessageTypes();
  }
  getAllMessageCategories(): string[] {
    return (
      this.dataSource ?? new MessagesDataSource()
    ).getAllMessageCategories();
  }
  getAuxiliaryOptions(
    id: Map<String, any>,
    theme: CometChatTheme,
    user?: CometChat.User,
    group?: CometChat.Group
  ): any {
    return (this.dataSource ?? new MessagesDataSource()).getAuxiliaryOptions(
      id,
      theme,
      user,
      group
    );
  }
  getId(): string {
    return (this.dataSource ?? new MessagesDataSource()).getId();
  }
  getDeleteMessageBubble(
    messageObject: CometChat.BaseMessage,
    theme: CometChatTheme,
    style?: TextBubbleStyle
  ) {
    return (this.dataSource ?? new MessagesDataSource()).getDeleteMessageBubble(
      messageObject,
      theme,
      style
    );
  }
  getGroupActionBubble(
    message: CometChat.BaseMessage,
    theme: CometChatTheme,
    style?: TextBubbleStyle
  ) {
    return (this.dataSource ?? new MessagesDataSource()).getGroupActionBubble(
      message,
      theme,
      style
    );
  }
  getTextMessageBubble(
    messageText: string,
    message: CometChat.TextMessage,
    alignment: MessageBubbleAlignment,
    theme: CometChatTheme,
    style?: TextBubbleStyle,
    additionalConfigurations?: any
  ) {
    return (this.dataSource ?? new MessagesDataSource()).getTextMessageBubble(
      messageText,
      message,
      alignment,
      theme,
      style,
      additionalConfigurations
    );
  }
  getVideoMessageBubble(
    videoUrl: string,
    message: CometChat.MediaMessage,
    theme: CometChatTheme,
    thumbnailUrl?: string,
    onClick?: Function,
    style?: BaseStyle
  ) {
    return (this.dataSource ?? new MessagesDataSource()).getVideoMessageBubble(
      videoUrl,
      message,
      theme,
      thumbnailUrl,
      onClick,
      style
    );
  }
  getImageMessageBubble(
    imageUrl: string,
    placeholderImage: string,
    message: CometChat.MediaMessage,
    theme: CometChatTheme,
    onClick?: Function,
    style?: ImageBubbleStyle
  ) {
    return (this.dataSource ?? new MessagesDataSource()).getImageMessageBubble(
      imageUrl,
      placeholderImage,
      message,
      theme,
      onClick,
      style
    );
  }
  getAudioMessageBubble(
    audioUrl: string,
    message: CometChat.MediaMessage,
    theme: CometChatTheme,
    title?: string,
    style?: BaseStyle
  ) {
    return (this.dataSource ?? new MessagesDataSource()).getAudioMessageBubble(
      audioUrl,
      message,
      theme,
      title,
      style
    );
  }
  getFileMessageBubble(
    fileUrl: string,
    message: CometChat.MediaMessage,
    theme: CometChatTheme,
    title?: string,
    style?: FileBubbleStyle
  ) {
    return (this.dataSource ?? new MessagesDataSource()).getFileMessageBubble(
      fileUrl,
      message,
      theme,
      title,
      style
    );
  }
  getFormMessageBubble(
    message: FormMessage,
    theme: CometChatTheme,
    style?: FormBubbleStyle,
    onSubmitClick?: Function
  ) {
    return (this.dataSource ?? new MessagesDataSource()).getFormMessageBubble(
      message,
      theme,
      style,
      onSubmitClick
    );
  }
  getSchedulerMessageBubble(
    message: SchedulerMessage,
    theme: CometChatTheme,
    style?: SchedulerBubbleStyle,
    onSubmitClick?: Function
  ) {
    return (
      this.dataSource ?? new MessagesDataSource()
    ).getSchedulerMessageBubble(message, theme, style, onSubmitClick);
  }
  getCardMessageBubble(
    message: CardMessage,
    theme: CometChatTheme,
    style?: CardBubbleStyle
  ) {
    return (this.dataSource ?? new MessagesDataSource()).getCardMessageBubble(
      message,
      theme,
      style
    );
  }
  getLastConversationMessage(
    conversation: CometChat.Conversation,
    loggedInUser: CometChat.User,
    additionalConfigurations?: any
  ): string {
    return (
      this.dataSource ?? new MessagesDataSource()
    ).getLastConversationMessage(
      conversation,
      loggedInUser,
      additionalConfigurations
    );
  }
  getDefaultDetailsTemplate(
    loggedInUser: CometChat.User,
    user: CometChat.User | null,
    group: CometChat.Group | null,
    theme: CometChatTheme
  ): CometChatDetailsTemplate[] {
    return (
      this.dataSource ?? new MessagesDataSource()
    ).getDefaultDetailsTemplate(loggedInUser, user, group, theme);
  }
  getAuxiliaryHeaderMenu(user?: CometChat.User, group?: CometChat.Group): any {
    return (this.dataSource ?? new MessagesDataSource()).getAuxiliaryHeaderMenu(
      user,
      group
    );
  }
  getAIOptions(
    user: CometChat.User | null,
    group: CometChat.Group | null,
    theme: CometChatTheme,
    id?: Map<String, any>,
    AIOptionsStyle?: AIOptionsStyle
  ): (CometChatMessageComposerAction | CometChatActionsView)[] {
    return (this.dataSource ?? new MessagesDataSource()).getAIOptions(
      user,
      group,
      theme,
      id,
      AIOptionsStyle
    );
  }
  getAllTextFormatters(formatterParams: any): CometChatTextFormatter[] {
    let formatters = [];
    const mentionsFormatter = formatterParams.disableMentions ? null : (this.dataSource ?? new MessagesDataSource()).getMentionsTextFormatter(
      formatterParams
    );
    const urlTextFormatter = (this.dataSource ?? new MessagesDataSource()).getUrlTextFormatter(
      formatterParams
    );
    if (mentionsFormatter) {
      formatters.push(mentionsFormatter);
    }
    if (urlTextFormatter) {
      formatters.push(urlTextFormatter);
    }
    return formatters;
  }

  getMentionsTextFormatter(params: any = {}): CometChatMentionsFormatter {
    return (
      this.dataSource ?? new MessagesDataSource()
    ).getMentionsTextFormatter(params);
  }
  getUrlTextFormatter(params: any = {}): CometChatUrlsFormatter {
    return (this.dataSource ?? new MessagesDataSource()).getUrlTextFormatter(
      params
    );
  }
  getMentionsFormattedText(
    message: CometChat.TextMessage,
    subtitle: string,
    additionalConfigurations: any
  ): string {
    return (
      this.dataSource ?? new MessagesDataSource()
    ).getMentionsFormattedText(message, subtitle, additionalConfigurations);
  }
}
