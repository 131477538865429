import { CometChat } from "@cometchat/chat-sdk-javascript";
import {
  CometChatTheme,
  CometChatUIKitConstants,
  MentionsTargetElement,
  MessageBubbleAlignment,
  localize,
} from "@cometchat/uikit-resources";
import {
  CometChatMentionsFormatter,
  CometChatTextFormatter,
  CometChatUIKitLoginListener,
  CometChatUIKitUtility,
} from "@cometchat/uikit-shared";
import { DataSourceDecorator } from "../../Shared/Framework/DataSourceDecorator";
import { ChatConfigurator } from "../../Shared/Framework/ChatConfigurator";

export class TextModeratorExtensionDecorator extends DataSourceDecorator {
  override getId(): string {
    return "textmoderator";
  }

  getModeratedtext(message: CometChat.TextMessage): string {
    let text: string = CometChatUIKitUtility.getExtensionData(message);
    if (text?.trim()?.length > 0) {
      return text;
    } else {
      return message.getText();
    }
  }

  override getLastConversationMessage(
    conversation: CometChat.Conversation,
    loggedInUser: CometChat.User,
    additionalConfigurations: any
  ): string {
    const message: CometChat.TextMessage = conversation.getLastMessage();

    if (
      message &&
      !message.getDeletedAt() &&
      message.getType() === CometChatUIKitConstants.MessageTypes.text &&
      message.getCategory() === CometChatUIKitConstants.MessageCategory.message
    ) {
      let subtitle = this.getModeratedtext(message);
      let messageObj = conversation.getLastMessage();

      let config = {
        ...additionalConfigurations,
        textFormatters:
          additionalConfigurations?.textFormatters &&
            additionalConfigurations?.textFormatters.length
            ? [...additionalConfigurations.textFormatters]
            : [this.getMentionsTextFormatter({ theme: additionalConfigurations.theme, disableMentions: additionalConfigurations.disableMentions })],
      };

      let textFormatters: Array<CometChatTextFormatter> = config.textFormatters;
      if (config && !config.disableMentions) {
        let mentionsTextFormatter!: CometChatMentionsFormatter;
        for (let i = 0; i < textFormatters.length; i++) {
          if (textFormatters[i] instanceof CometChatMentionsFormatter) {
            mentionsTextFormatter = textFormatters[
              i
            ] as CometChatMentionsFormatter;
            mentionsTextFormatter.setMessage(messageObj);
            if (messageObj.getMentionedUsers().length) {
              mentionsTextFormatter.setCometChatUserGroupMembers(
                messageObj.getMentionedUsers()
              );
            }
            mentionsTextFormatter.setLoggedInUser(
              CometChatUIKitLoginListener.getLoggedInUser()!
            );
            if (mentionsTextFormatter) {
              break;
            }
          }
        }
        if (!mentionsTextFormatter) {
          mentionsTextFormatter =
            ChatConfigurator.getDataSource().getMentionsTextFormatter({
              messageObj,
              ...config,
              alignment: null,
              theme: additionalConfigurations.theme,
            });
          textFormatters.push(mentionsTextFormatter);
        }
      }

      if (
        messageObj &&
        messageObj instanceof CometChat.TextMessage
      ) {
        for (let i = 0; i < textFormatters.length; i++) {
          subtitle = textFormatters[i].getFormattedText(subtitle, { mentionsTargetElement: MentionsTargetElement.conversation })!;
        }
      }
      if (
        message &&
        conversation?.getConversationType() != CometChat.RECEIVER_TYPE.USER
      ) {
        if (
          message?.getSender().getUid() ==
          CometChatUIKitLoginListener?.getLoggedInUser()?.getUid()
        ) {
          subtitle = `${localize("YOU")}: ${subtitle}`;
        } else {
          subtitle = `${message?.getSender().getName()}:  ${subtitle}`;
        }
      }
      return subtitle;
    } else {
      return super.getLastConversationMessage(
        conversation,
        loggedInUser,
        additionalConfigurations
      );
    }
  }

  override getTextMessageContentView(
    message: CometChat.TextMessage,
    alignment: MessageBubbleAlignment,
    theme: CometChatTheme,
    additionalConfigurations?: any
  ) {
    let moderatedText = this.getModeratedtext(message);
    if (this.getModeratedtext(message) !== message.getText()) {
      message.setText(moderatedText);
    }
    return super.getTextMessageContentView(
      message,
      alignment,
      theme,
      additionalConfigurations
    );
  }
}
