import { CSSProperties } from "react";
import { BaseStyle, TabItemStyle } from "@cometchat/uikit-shared";
import { CometChatTabItem, TabAlignment } from "@cometchat/uikit-resources";
import { TabsStyle } from "./TabsStyle";

export const TabsWrapperStyle = (tabStyle: BaseStyle | undefined) => {

    return {
        height: tabStyle?.height || "100%", 
        width: tabStyle?.width || "100%", 
        borderRadius: tabStyle?.borderRadius || "0",
        border: tabStyle?.border || "0 none",
        background: tabStyle?.background || "transparent",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        position: 'relative'
    } as CSSProperties;
}

export const ButtonStyle = (style: TabItemStyle, active: boolean, iconURL?: string) => {
   
    return {
        background: "inherit",
        buttonTextFont: active ? style?.activeTitleTextFont ?? style?.titleTextFont : style?.titleTextFont,
        buttonTextColor: active ? style?.activeTitleTextColor ?? style?.titleTextColor : style?.titleTextColor,
        buttonIconTint: active ? style?.activeIconTint ?? style?.iconTint : style?.iconTint,
        height: "100%",
        width: "100%",
        border: style?.border,
        borderRadius: style?.borderRadius,
        gap: "8px",
        padding: "0",
        justifyContent: !iconURL ? "center" : "",
    };
}

export const getTabListStyle  = (tabsStyle: TabsStyle | undefined, tabAlignment: TabAlignment) : CSSProperties => {
    let positionProperties;
    if(tabAlignment === TabAlignment.top || tabAlignment === TabAlignment.left){
        positionProperties = {
            top: "0",
            left: "0"
        };
    }else if(tabAlignment === TabAlignment.bottom){
        positionProperties = {
            bottom: "2px",
            left: "2px"
        };
    }else{
        positionProperties = {
            top: "0",
            right: "0"
        };
    }
    
    return {
        ...positionProperties,
        position: 'absolute',
        zIndex: "2",
        height: tabsStyle?.tabListHeight || '100%', 
        width: tabsStyle?.tabListWidth || '100%', 
        border: tabsStyle?.tabListBorder || '0 none', 
        borderRadius: tabsStyle?.tabListBorderRadius || '0', 
        background: tabsStyle?.tabListBackground || 'transparent',
        boxShadow: tabsStyle?.tabListBoxShadow || "0", 
        padding: tabsStyle?.tabListPadding || "0",
        boxSizing: "border-box"
    };
}

export function getTabContentStyle(tabsStyle: TabsStyle | undefined) {

    return {
        height: tabsStyle?.tabPaneHeight || "100%",
        width: tabsStyle?.tabPaneWidth || "100%",
        display: "flex",
        position: "absolute",
        bottom: "0"
    } as CSSProperties;
}

export function tabItemWrapperStyle() {
    return {
        display: 'flex',
        justifyContent: "center",
        height: '100%', 
        width: '100%', 
    }
}

export const getTabItemStyle = (tabAlignment: TabAlignment | undefined, tab: CometChatTabItem, activeTab: CometChatTabItem | null, placement: string,tabsStyle:TabsStyle|undefined) => {

    let active = activeTab?.id === tab?.id;
    let style = tab?.style as TabItemStyle;
    let alignment: string = tabAlignment === TabAlignment.top || tabAlignment === TabAlignment.bottom ? "row" : "column";
    let borderRadiusStyle = style?.borderRadius;
    let borderRadius = placement === 'first' ? `${borderRadiusStyle} 0 0 ${borderRadiusStyle}` : placement === 'last' ? `0 ${borderRadiusStyle} ${borderRadiusStyle} 0` : '0';
    if(active && style.activeBorderRadius) {
        borderRadius = style.activeBorderRadius;
    }

    let boxShadow = "0";
    if(active && style?.boxShadow) {
        boxShadow = style.boxShadow;
    }

    return {
        display: "flex",
        flexDirection: alignment,
        justifyContent: "center",
        width: style?.width,
        height: style?.height,
        backgroundColor:  active ?  style?.activeBackground ?? style?.background : style?.background,
        borderRadius: borderRadius,
        boxShadow: boxShadow,
    } as CSSProperties;
}

export const TabPaneContentViewStyle = (tabStyle: BaseStyle | undefined) => { 

    let {borderRadius} = tabStyle ?? {};
    return {
        height: '100%', 
        width: '100%', 
        borderRadius
    } as CSSProperties;
};

export function draggableStyle() {
    return {
        width: '100%',
        height: '100%',
        background: 'transparent'
    }
}

export function tabPaneContentStyle(tab: CometChatTabItem, activeTab: CometChatTabItem | null) {
    return (tab.id !== activeTab?.id) 
    ? {
        display:"none"
    } : {
        display: "flex", 
        height: '100%', 
        width: '100%', 
    }
}