import {
  AvatarStyle,
  DateStyle,
  ListItemStyle,
} from "@cometchat/uikit-elements";
import { CallLogHistoryStyle, ListStyle } from "@cometchat/uikit-shared";
import { CometChatTheme, fontHelper } from "@cometchat/uikit-resources";

export function getSubtitleStyle(
  theme: CometChatTheme,
  style: CallLogHistoryStyle
): DateStyle {
  return {
    background: "transparent",
    textColor: style?.dateTextColor || theme.palette.getAccent600(),
    textFont: style?.dateTextFont || fontHelper(theme.typography.subtitle2),
  };
}

export function getAvatarStyle(
  theme: CometChatTheme,
  avatarStyle?: AvatarStyle
) {
  return new AvatarStyle({
    borderRadius: avatarStyle?.borderRadius || "24px",
    width: avatarStyle?.width || "36px",
    height: avatarStyle?.height || "36px",
    border: avatarStyle?.border || "none",
    backgroundColor:
      avatarStyle?.backgroundColor || theme.palette.getAccent700(),
    nameTextColor: avatarStyle?.nameTextColor || theme.palette.getAccent900(),
    backgroundSize: avatarStyle?.backgroundSize || "cover",
    nameTextFont:
      avatarStyle?.nameTextFont || fontHelper(theme.typography.subtitle1),
    outerViewBorderWidth: avatarStyle?.outerViewBorderWidth || "",
    outerViewBorderSpacing: avatarStyle?.outerViewBorderSpacing || "",
    outerViewBorderColor: avatarStyle?.outerViewBorderColor || "",
    outerViewBorderRadius: avatarStyle?.outerViewBorderRadius || "",
  });
}

export function getListItemStyle(
  theme: CometChatTheme,
  listItemStyle?: ListItemStyle
) {
  return new ListItemStyle({
    height: listItemStyle?.height || "45px",
    width: listItemStyle?.width || "100%",
    background: listItemStyle?.background || theme.palette.getBackground(),
    activeBackground:
      listItemStyle?.activeBackground || theme.palette.getAccent100(),
    borderRadius: listItemStyle?.borderRadius || "0",
    border: listItemStyle?.border || "none",
    separatorColor:
      listItemStyle?.separatorColor || theme.palette.getAccent200(),
    hoverBackground:
      listItemStyle?.hoverBackground || theme.palette.getAccent50(),
    padding: listItemStyle?.padding || "0",
  });
}

export function getCallStatusStyle(
  theme: CometChatTheme,
  style: CallLogHistoryStyle
) {
  return {
    font: style?.callStatusTextFont || fontHelper(theme.typography.text2),
    color: style?.callStatusTextColor || theme.palette.getAccent(),
  };
}

export function getContainerStyle(
  theme: CometChatTheme,
  callLogsStyle: CallLogHistoryStyle
): React.CSSProperties {
  return {
    height: callLogsStyle?.height || "100%",
    width: callLogsStyle?.width || "100%",
    background: callLogsStyle?.background || theme.palette.getBackground(),
    borderRadius: callLogsStyle?.borderRadius || "0",
    border: callLogsStyle?.border || "none",
  };
}

export function getListStyle(
  theme: CometChatTheme,
  callLogsStyle: CallLogHistoryStyle
): ListStyle {
  return new ListStyle({
    height: "90%",
    emptyStateTextColor:
      callLogsStyle?.emptyStateTextColor || theme.palette.getAccent600(),
    emptyStateTextFont:
      callLogsStyle?.emptyStateTextFont || fontHelper(theme.typography.caption1),
    errorStateTextColor:
      callLogsStyle?.errorStateTextColor || theme.palette.getAccent600(),
    errorStateTextFont:
      callLogsStyle?.errorStateTextFont || fontHelper(theme.typography.caption1),
    loadingIconTint:
      callLogsStyle?.loadingIconTint || theme.palette.getAccent600(),
  });
}

export function getCallDurationStyle(
  theme: CometChatTheme,
  style: CallLogHistoryStyle
) {
  return {
    font: style?.callDurationTextColor || fontHelper(theme.typography.caption1),
    color: style?.callDurationTextColor || theme.palette.getAccent500(),
  };
}

export function getButtonContainerStyle(): React.CSSProperties {
  return {
    marginRight: "15px",
  };
}

export function getBackButtonStyle(
  theme: CometChatTheme,
  style: CallLogHistoryStyle
) {
  return {
    height: "24px",
    width: "24px",
    border: "none",
    borderRadius: "0",
    background: "transparent",
    buttonIconTint: style?.backIconTint || theme.palette.getPrimary(),
  };
}

export function getDateSeparator(
  theme: CometChatTheme,
  style: CallLogHistoryStyle
) {
  return {
    textFont:
      style?.dateSeparatorTextFont || fontHelper(theme.typography.text3),
    textColor: style?.dateSeparatorTextColor || theme.palette.getAccent700(),
    background: "transparent",
  };
}

export function getDividerStyle(
  theme: CometChatTheme,
  style: CallLogHistoryStyle
) {
  return {
    height: "1px",
    width: "100%",
    background: style?.dividerColor || theme.palette.getAccent200(),
  };
}

export function getTitleStyle(
  theme: CometChatTheme,
  style: CallLogHistoryStyle
) {
  return {
    font: style?.titleFont || fontHelper(theme.typography.text1),
    color: style?.titleColor || theme.palette.getAccent(),
    background: "transparent",
  };
}
