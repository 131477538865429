import { CometChat } from "@cometchat/chat-sdk-javascript";
import {
    CometChatActionsView,
    CometChatMessageComposerAction,
    CometChatTheme,
    CometChatUIKitConstants,
    CometChatUIEvents,
    PanelAlignment,
    localize,
} from "@cometchat/uikit-resources";
import { DataSource } from "../../Shared/Framework/DataSource";
import { DataSourceDecorator } from "../../Shared/Framework/DataSourceDecorator";
import { AIAssistBotConfiguration, AIOptionsStyle } from "@cometchat/uikit-shared";
import AIAssistBotOptoinsView from "./AIAssistBotOptionsView";
import AIAssistBotChatView from "./AIAssistBotChatView";

export class AIAssistBotDecorator extends DataSourceDecorator {
    public configuration?: AIAssistBotConfiguration;
    public newDataSource!: DataSource;
    public loggedInUser!: CometChat.User | null;
    public user!: CometChat.User;
    public group!: CometChat.Group;
    public bots: CometChat.User[] | [] = [];
    public theme: CometChatTheme = new CometChatTheme({});

    constructor(
        dataSource: DataSource,
        configuration?: AIAssistBotConfiguration
    ) {
        super(dataSource);
        this.newDataSource = dataSource;
        this.configuration = configuration!;
        setTimeout(() => {
            this.addMessageListener();
            this.getAllBots();
        }, 1000);
    }

    override getId(): string {
        return "aiassistbot";
    }

    override getAIOptions(user: CometChat.User | null, group: CometChat.Group | null, theme: CometChatTheme, id?: any, aiOptionsStyle?: AIOptionsStyle): (CometChatMessageComposerAction | CometChatActionsView)[] {
        this.user = user!;
        this.group = group!;

        const generalAskBotText = localize("COMETCHAT_ASK_AI_BOT");
        const askText = localize("COMETCHAT_ASK_BOT");

        const numberOfBots = this.bots?.length;
        const titleName = numberOfBots > 1 ? generalAskBotText : `${askText} ${this.bots[0]?.getName()}`;

        if (!id?.parentMessageId) {
            let style;
            if (aiOptionsStyle) {
                style = {
                    titleColor: this.configuration?.assistBotStyle?.buttonTextColor || aiOptionsStyle?.listItemTextColor,
                    titleFont: this.configuration?.assistBotStyle?.buttonTextFont || aiOptionsStyle?.listItemTextFont,
                    background: this.configuration?.assistBotStyle?.buttonBackground || aiOptionsStyle?.listItemBackground
                }
            }
            const messageComposerActions: (CometChatMessageComposerAction | CometChatActionsView)[] = super.getAIOptions(user, group, theme, id, aiOptionsStyle);

            if(numberOfBots === 1) {
                const newAction: CometChatMessageComposerAction =
                    new CometChatMessageComposerAction({
                        title: titleName,
                        onClick: () => { this.onOptionClick(this.bots[0]);},
                        id: "ai-assist-bot",
                        iconURL: "",
                        iconTint: "",
                        titleColor: style?.titleColor,
                        titleFont: style?.titleFont,
                        background: style?.background
                    });
                messageComposerActions.push(newAction);
            }else if(numberOfBots > 1) {
                const botList = this.bots.map((bot) => {
                    return new CometChatMessageComposerAction({
                        title: bot.getName(),
                        onClick: () => {this.onOptionClick(bot);},
                        id: bot.getUid(),
                    });
                });

                const newAction: CometChatActionsView = new CometChatActionsView({
                    title: titleName,
                    customView: (callBacks: any) => {
                        return <AIAssistBotOptoinsView title={titleName} configuration={this.configuration} backCallback={callBacks?.backAction} closeCallback={callBacks?.closePopover} bots={botList} />
                    },
                    id: "ai-assist-bot",
                    iconURL: "",
                    iconTint: "",
                    titleColor: style?.titleColor,
                    titleFont: style?.titleFont,
                    background: style?.background,
                });

                messageComposerActions.push(newAction);
            }
            return messageComposerActions;
        } else {
            return super.getAIOptions(user, group, theme, id, aiOptionsStyle);
        }
    }

    onMessageSent = async (message: string, bot: CometChat.User): Promise<string> => {
        return new Promise(async (resolve, reject) => {
            try{
                let receiverId: string = this.user
                    ? this.user?.getUid()
                    : this.group?.getGuid();
                let receiverType: string = this.user
                    ? CometChatUIKitConstants.MessageReceiverType.user
                    : CometChatUIKitConstants.MessageReceiverType.group;
                let configuration;
                if (this.configuration?.apiConfiguration) {
                    configuration = await this.configuration?.apiConfiguration(
                        bot,
                        this.user,
                        this.group
                    );
                }
                const response = await CometChat.askBot(
                    receiverId,
                    receiverType,
                    bot.getUid(),
                    message,
                    configuration ? configuration : {}
                );
                return resolve(response);
            }catch(e){
                reject(e);
            }
        });
    };

    closeChat = () => {
        CometChatUIEvents.ccHidePanel.next(PanelAlignment.messages);
    };

    onOptionClick = (bot: CometChat.User) => {
        CometChatUIEvents.ccShowPanel.next({
            child: <AIAssistBotChatView bot={bot} sender={this.loggedInUser!} configuration={this.configuration} messageSendCallBack={this.onMessageSent} closeCallback={this.closeChat} />,
            position: PanelAlignment.messages,
        });
    };

    private getAllBots(): void {
        new CometChat.UsersRequestBuilder().setLimit(30).setTags(["aibot"]).build().fetchNext().then(
            (bots: CometChat.User[] | []) => {
                this.bots = bots;
            }
        )
    }

    private addMessageListener(): void {
        CometChat.getLoggedinUser().then((user: CometChat.User | null) => {
            if (user) {
                this.loggedInUser = user;
            }
        });

        CometChatUIEvents.ccActiveChatChanged.subscribe(() => {
            this.closeChat();
        });
    }
}