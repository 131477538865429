import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatTheme, fontHelper } from '@cometchat/uikit-resources';
import { MessageHeaderStyle, MessagesConfiguration, MessagesStyle, UsersConfiguration, UsersStyle, WithMessagesStyle } from '@cometchat/uikit-shared';
import { LabelStyle } from '@cometchat/uikit-elements';
import { CSSProperties } from 'react';

const labelStyle: LabelStyle = {
    height: '100%',
    width: '100%',
    background: "transparent",
    border: "none",
    borderRadius: "0",
    textFont: "700 22px Inter, sans-serif",
    textColor: "rgba(20, 20, 20, 0.33)",
};

const WithMessagesWrapperStyle = {
    display: 'flex',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative'
} as CSSProperties;

const WithMessagesMainStyle = {
    width: 'calc(100% - 280px)',
    height: '100%'
} as CSSProperties;

const MobileLayoutStyle = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0'
} as CSSProperties;

const EmptyMessagesDivStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'inherit',
    border: 'none',
    borderRadius: 'inherit',
    width: '100%',
    height: 'auto'
} as CSSProperties;

const WithMessagesSidebarStyle = {
    width: '280px',
    height: '100%',
    position: 'relative',
} as CSSProperties;

export const getUsersWrapperStyles = (usersWithMessagesStyle: WithMessagesStyle | undefined, theme: CometChatTheme) => {
    return {
        ...WithMessagesWrapperStyle,
        width: '100%',
        height: '100%',
        border: `1px solid ${theme.palette.getAccent100()}`,
        borderRadius: '0',
        background: theme.palette.getBackground(),
        ...usersWithMessagesStyle
    } as CSSProperties
}

export const getWithMessagesSidebarStyle = (usersWithMessagesStyle: WithMessagesStyle | undefined, theme: CometChatTheme, isMobileView: boolean | undefined, activeUser: CometChat.User | undefined) => {
    if (isMobileView) {
        return getMobileViewSidebarStyle(activeUser);
    } else {
        return getDesktopViewSidebarStyle(usersWithMessagesStyle, theme);
    }
};

const getMobileViewSidebarStyle = (activeUser: CometChat.User | undefined) => {

    const visibility = activeUser ? { "visibility": "hidden" } : { "visibility": "visible" };
    return {
        ...visibility,
        ...MobileLayoutStyle,
        borderRadius: 'inherit',
    } as CSSProperties
}

const getDesktopViewSidebarStyle = (usersWithMessagesStyle: WithMessagesStyle | undefined, theme: CometChatTheme) => {

    const borderRadius = usersWithMessagesStyle?.borderRadius || 0;
    return {
        ...WithMessagesSidebarStyle,
        borderRight: usersWithMessagesStyle?.border || `1px solid ${theme.palette.getAccent100()}`,
        borderRadius: `${borderRadius} 0 0 ${borderRadius}`
    } as CSSProperties
}

export const getWithMessagesMainStyle = (usersWithMessagesStyle: WithMessagesStyle | undefined, isMobileView: boolean | undefined, activeUser: CometChat.User | undefined) => {
    if (isMobileView) {
        return getMobileViewMainStyle(activeUser);
    } else {
        return getDesktopViewMainStyle(usersWithMessagesStyle);
    }
}

const getMobileViewMainStyle = (activeUser: CometChat.User | undefined) => {

    const visibility = activeUser ? { "visibility": "visible" } : { "visibility": "hidden" };
    return {
        ...visibility,
        ...MobileLayoutStyle,
        borderRadius: 'inherit',
    } as CSSProperties;
}

const getDesktopViewMainStyle = (usersWithMessagesStyle: WithMessagesStyle | undefined) => {

    const borderRadius = usersWithMessagesStyle?.borderRadius || 0;
    return {
        ...WithMessagesMainStyle,
        borderRadius: `0 ${borderRadius} ${borderRadius} 0`
    }
}

export const getLabelStyle = (usersWithMessagesStyle: WithMessagesStyle | undefined, theme: CometChatTheme) => {

    return {
        ...labelStyle,
        textFont: usersWithMessagesStyle?.messageTextFont || fontHelper(theme.typography.title1),
        textColor: usersWithMessagesStyle?.messageTextColor || theme.palette.getAccent600(),
    } as LabelStyle;
}

export const getUsersStyle = (usersConfiguration: UsersConfiguration | undefined) => {

    const usersStyle = usersConfiguration?.usersStyle;
    return {
        width: "100%",
        height: "100%",
        border: "0 none",
        borderRadius: "inherit",
        background: "inherit",
        ...usersStyle
    } as UsersStyle;
}

export const getMessageHeaderStyle = (usersWithMessagesStyle: WithMessagesStyle | undefined, messagesConfiguration: MessagesConfiguration | undefined, isMobileView: boolean | undefined) => {

    const borderRadius = usersWithMessagesStyle?.borderRadius;
    return {
        borderRadius: isMobileView ? `${borderRadius} ${borderRadius} 0 0` : `0px ${borderRadius} 0px 0px`,
        ...messagesConfiguration?.messageHeaderConfiguration?.messageHeaderStyle
    } as MessageHeaderStyle;
}

export const getMessageComposerStyle = (usersWithMessagesStyle: WithMessagesStyle | undefined, messagesConfiguration: MessagesConfiguration | undefined, isMobileView: boolean | undefined) => {

    const borderRadius = usersWithMessagesStyle?.borderRadius;
    return {
        borderRadius: isMobileView ? `0 0 ${borderRadius} ${borderRadius}` : `0px 0 ${borderRadius} 0px`,
        ...messagesConfiguration?.messageComposerConfiguration?.messageComposerStyle
    }
}

export const getMessagesStyle = (
    messagesStyle?: MessagesStyle,
    withMessagesStyle?: WithMessagesStyle | undefined
) => {
    return { ...{ background: withMessagesStyle?.background }, ...messagesStyle }
};

export const getEmptyMessageLayoutStyle = (isMobileView: boolean | undefined, activeUser: CometChat.User | undefined) => {

    let visibility = { visibility: 'hidden' };
    if (!activeUser && !isMobileView) {
        visibility = { "visibility": "visible" };
    }

    return {
        ...EmptyMessagesDivStyle,
        ...visibility,
        width: `calc(100% - 280px)`
    } as CSSProperties
}

export const getMessageInformationStyle = (messagesConfiguration: MessagesConfiguration | undefined, isMobileView: boolean | undefined) => {
    const desktopWidth = messagesConfiguration?.messageListConfiguration?.messageInformationConfiguration.messageInformationStyle.width || "320px";
    const desktopHeight = messagesConfiguration?.messageListConfiguration?.messageInformationConfiguration.messageInformationStyle.height ||  "620px";
    return {
        ...messagesConfiguration?.messageListConfiguration?.messageInformationConfiguration.messageInformationStyle,
        width: isMobileView ? "100vw" : desktopWidth,
        height: isMobileView ? "100vh" : desktopHeight,
    }
}
