/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { CometChatUIKit } from "../../Shared/CometChatUIKit/CometChatUIKit";

function Hooks(
    loggedInUser: any,
	setLoggedInUser: Function,
    requestBuilder: any,
    setCallBuilder: Function,
    getCallList: Function,
    attachListeners: Function,
    subscribeToEvents: Function,
    detachListeners: Function,
    onErrorCallback: Function
) {
    useEffect(
        () => {
            CometChatUIKit.getLoggedinUser().then(
                (user) => {
                    setLoggedInUser(user);
                },
                (error: CometChat.CometChatException) => {
                    onErrorCallback(error);
                }
            );
        },
        [setLoggedInUser, onErrorCallback]
    );

    useEffect(()=>{
        let unsubscribeFromEvents : () => void;
        if(loggedInUser){
            unsubscribeFromEvents = subscribeToEvents?.();
            requestBuilder.current = setCallBuilder();
            getCallList?.();
            attachListeners?.();
            subscribeToEvents?.();
        }
        return () => {
            detachListeners?.()
            unsubscribeFromEvents?.();
        }
    }, [loggedInUser]);
}

export { Hooks };