import {
    AIAssistBotConfiguration,
    AIBotMessageBubbleStyle,
    AISenderMessageBubbleStyle,
    } from "@cometchat/uikit-shared";
import {
    CometChatTheme,
    DatePatterns,
    MessageBubbleAlignment,
    Receipts,
    fontHelper,
} from "@cometchat/uikit-resources";
import { getMessageBubbleDateStyle, getMessageReceiptStyle } from "./style";
import { CometChatTextBubble } from "@cometchat/uikit-elements";
import { createComponent } from "@lit-labs/react";
import React from 'react';


const TextMessageBubble = createComponent({
    tagName: 'cometchat-text-bubble',
    elementClass: CometChatTextBubble,
    react: React
});

export const getBubbleAlignment = (
    message: CometChat.TextMessage,
    sender: CometChat.User
): MessageBubbleAlignment => {
    // @ts-ignore
    if (message.sender.getUid() === sender.getUid()) {
        return MessageBubbleAlignment.right;
    } else {
        return MessageBubbleAlignment.left;
    }
}

export const getContentView = (
    message: CometChat.TextMessage,
    theme: CometChatTheme,
    alignment: MessageBubbleAlignment,
    configuration: AIAssistBotConfiguration
): JSX.Element => {
    let style: AIBotMessageBubbleStyle | AISenderMessageBubbleStyle = {};

    if (alignment === MessageBubbleAlignment.right) {
        style = configuration?.senderMessageBubbleStyle || {};
    } else {
        style = configuration?.botMessageBubbleStyle || {};
    }

    const defaultStyle: any = {
        textFont: style?.textFont || fontHelper(theme.typography.text3),
    };

    if (alignment === MessageBubbleAlignment.right) {
        defaultStyle["textColor"] = style?.textColor || theme.palette.getAccent900();
    } else {
        defaultStyle["textColor"] = style?.textColor || theme.palette.getAccent();
    }

    return <TextMessageBubble text={message.getText()} textStyle={defaultStyle} />
}

export const getBubbleFooterView = (
    item: CometChat.TextMessage,
    configuration: AIAssistBotConfiguration,
    theme: CometChatTheme
): JSX.Element => {
    let status;
    let displayReceipt = false;

    if (
        item.getStatus() === "send" ||
        item.getStatus() === "wait" ||
        item.getStatus() === "error"
    ) {
        if (item.getStatus() === "wait") {
            status = Receipts.wait;
        } else if (item.getStatus() === "sent") {
            status = Receipts.sent;
        } else if (item.getStatus() === "error") {
            status = Receipts.error;
        }

        displayReceipt = true;
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'inline-block' }}>
                <cometchat-date
                    timestamp={item.getSentAt()}
                    dateStyle={JSON.stringify(getMessageBubbleDateStyle(theme))}
                    pattern={DatePatterns.time}>
                </cometchat-date>
            </div>
            {
                displayReceipt ?
                    <div style={{position: 'relative', height: '20px', width: '20px'}}>
                        <cometchat-receipt
                            receipt={status}
                            waitIcon={configuration?.loadingIconURL}
                            errorIcon={configuration?.errorIconURL}
                            receiptStyle={JSON.stringify(getMessageReceiptStyle(configuration?.assistBotStyle!, theme))}
                        ></cometchat-receipt>
                    </div> :
                    null
            }
        </div>
    )
}


