import {
  AvatarStyle,
  DateStyle,
  LabelStyle,
  ListItemStyle,
  LoaderStyle,
  ReceiptStyle,
} from "@cometchat/uikit-elements";
import {
  CometChatTheme,
  CometChatUIKitConstants,
  MessageBubbleAlignment,
  MessageListAlignment,
  fontHelper,
} from "@cometchat/uikit-resources";
import {
  BaseStyle,
  ListStyle,
  MessageListStyle,
  ReactionInfoConfiguration,
  ReactionInfoStyle,
  ReactionListStyle,
  ReactionsStyle,
  StickersConstants,
} from "@cometchat/uikit-shared";
import { CSSProperties } from "react";

/**
 * Generates the style object for the new unread-messages view, visible when the user is not at the bottom and receives a new message
 *
 * @returns {CSSProperties}
 */
export const newMessageIndicatorStyle: () => CSSProperties = () => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "fit-content",
    width: "100%",
  };
};

/**
 * Generates the style object for the custom header view, visible on the top of the message list
 *
 * @returns {CSSProperties}
 */
export const headerStyle: () => CSSProperties = () => {
  return {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    height: "auto",
  };
};

/**
 * Returns the style object for the custom footer view, visible on the bottom of the message list. By default, smart replies are visible  for the message received.
 *
 * @returns {CSSProperties}
 */
export const footerStyle: () => CSSProperties = () => {
  return {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    height: "auto",
  };
};

/**
 * Provides the style object for the CometChatList wrapper which renders a list of messages

 *
 * @returns {CSSProperties}
 */
export const listWrapperStyle: () => CSSProperties = () => {
  return {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    boxSizing: "border-box",
  };
};

/**
 * Generates the style object for the MessageList wrapper which renders the CometChatList component
 *
 * @param {(MessageListStyle | undefined)} messageListStyle
 * @param {CometChatTheme} theme
 * @returns {CSSProperties}
 */
export const wrapperStyle: (
  messageListStyle: MessageListStyle | undefined,
  theme: CometChatTheme
) => CSSProperties = (
  messageListStyle: MessageListStyle | undefined,
  theme: CometChatTheme
) => {
    return {
      height: "100%",
      width: "100%",
      flex: "1 1 0",
      order: "2",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      border: `1px solid ${theme.palette.getAccent50()}`,
      borderRadius: "inherit",
      background: theme.palette.getBackground(),
      ...messageListStyle,
    };
  };

/**
 * Style object for the threadView in the CometChatMessageBubble which shows the message count of a specific thread visible below the CometChatMessageBubble in a particular user/group.
 *
 * @param {CometChat.BaseMessage} message
 * @param {CometChatTheme} theme
 * @param {(CometChat.User | null)} loggedInUser
 * @param {MessageListStyle} [messageListStyle={}]
 * @param {MessageBubbleAlignment} [alignment]
 * @returns {CSSProperties}
 */
export const messageThreadViewStyle: (
  message: CometChat.BaseMessage,
  theme: CometChatTheme,
  loggedInUser: CometChat.User | null,
  messageListStyle: MessageListStyle | undefined,
  alignment?: MessageBubbleAlignment
) => CSSProperties = (
  message: CometChat.BaseMessage,
  theme: CometChatTheme,
  loggedInUser: CometChat.User | null,
  messageListStyle: MessageListStyle = {},
  alignment?: MessageBubbleAlignment
) => {

    const baseStyle = {
      height: "100%",
      width: "100%",
      border: "none",
      borderRadius: "0",
      background: "transparent",
      buttonIconTint: messageListStyle.threadReplyIconTint || theme.palette.getAccent600(),
      display: "flex",
      alignItems: "center",
      gap: "5px",
      buttonTextColor: messageListStyle?.threadReplyTextColor || theme.palette.getAccent(),
      buttonTextFont: messageListStyle?.threadReplyTextFont || fontHelper(theme.typography.text2),
      iconHeight: "15px",
      iconWidth: "15px"
    };

    if ((!message.getSender() || message.getSender().getUid() === loggedInUser?.getUid()) && alignment != MessageBubbleAlignment.left) {
      return {
        ...baseStyle,
        flexFlow: "row-reverse",
      };
    } else {
      return {
        ...baseStyle,
        flexFlow: "row",
      };
    }
  };



/**
 * Style object for the new message indicator text visible upon receiving a new message while the screen is not at the last message of the chat.
 *
 * @param {CometChatTheme} theme
 * @returns {CSSProperties}
 */
export const newMessageTextStyleStyle: (theme: CometChatTheme) => CSSProperties = (theme: CometChatTheme) => {
  return {
    height: "fit-content",
    width: "fit-content",
    background: theme.palette.getPrimary(),
    display: "flex",
    justifyContent: "center",
    buttonTextFont: fontHelper(theme.typography.text2),
    buttonTextColor: theme.palette.getAccent("dark"),
    border: "none",
    borderRadius: "12px",
    padding: "2px 6px"
  };
};



/**
 * Default style object for CometChatMessageBubble component which renders all types of supported message types.
 *
 * @param {CometChat.BaseMessage} message
 * @param {CometChatTheme} theme
 * @param {(MessageListAlignment | undefined)} alignment
 * @param {(CometChat.User | null)} loggedInUser
 * @returns {BaseStyle}
 */
export const messageBubbleStyle: (
  message: CometChat.BaseMessage,
  theme: CometChatTheme,
  alignment: MessageListAlignment | undefined,
  loggedInUser: CometChat.User | null
) => BaseStyle = (
  message: CometChat.BaseMessage,
  theme: CometChatTheme,
  alignment: MessageListAlignment | undefined,
  loggedInUser: CometChat.User | null
) => {
    const baseBackground: BaseStyle = {
      background: "transparent",
      borderRadius: "12px",
    };

    const coloredBackground: BaseStyle = {
      background: theme.palette.getSecondary(),
      borderRadius: "12px",
    };

    if (message.getDeletedAt()) {
      return {
        ...baseBackground,
      };
    } else if (
      message?.getType() === CometChatUIKitConstants.calls.meeting &&
      (!message?.getSender() || message?.getSender().getUid() === loggedInUser?.getUid())
    ) {
      return {
        ...coloredBackground,
        background: theme.palette.getPrimary(),
        border: `none`,
      };
    } else if (message?.getType() === StickersConstants.sticker) {
      return baseBackground;
    } else if (
      !message.getDeletedAt() &&
      message.getCategory() === CometChatUIKitConstants.MessageCategory.message &&
      message.getType() === CometChatUIKitConstants.MessageTypes.text &&
      (!message.getSender() || loggedInUser?.getUid() === message.getSender().getUid())
    ) {
      return {
        ...coloredBackground,
        background:
          alignment === MessageListAlignment.left
            ? theme.palette.getSecondary()
            : theme.palette.getPrimary(),
      };
    } else if (
      !message.getDeletedAt() &&
      message.getCategory() === CometChatUIKitConstants.MessageCategory.message &&
      message.getType() === CometChatUIKitConstants.MessageTypes.audio
    ) {
      return {
        ...coloredBackground,
        borderRadius: "",
      };
    } else if (
      message.getType() === CometChatUIKitConstants.MessageTypes.groupMember ||
      message.getCategory() === CometChatUIKitConstants.MessageCategory.call
    ) {
      return {
        ...baseBackground,
        border: `1px solid ${theme.palette.getSecondary()}`,
      };
    } else if (
      !message.getDeletedAt() &&
      message.getCategory() === CometChatUIKitConstants.MessageCategory.interactive
    ) {
      return {
        ...coloredBackground,
        width: "300px",
      };
    } else {
      return coloredBackground;
    }
  };

/**
 * Default style object for receipt status of the message if it's read  sent or  delivered.
 *
 * @param {CometChatTheme} theme
 * @param {CometChat.BaseMessage} message
 * @returns {ReceiptStyle}
 */
export const messageReceiptStyle: (theme: CometChatTheme, message: CometChat.BaseMessage) => ReceiptStyle = (theme: CometChatTheme, message: CometChat.BaseMessage) => {
  const isTextMessage = message?.getType() === CometChatUIKitConstants.MessageTypes.text;
  return new ReceiptStyle({
    waitIconTint: theme.palette.getAccent700(),
    sentIconTint: theme.palette.getAccent600(),
    deliveredIconTint: theme.palette.getAccent600(),
    readIconTint: isTextMessage ? theme.palette.getBackground() : theme.palette.getPrimary(),
    errorIconTint: theme.palette.getError(),
    height: "11px",
    width: "12px",
    background:"none"
  });
};

/**
 * Default style object for statusInfo view which renders the time when the message was sent and receipt status
of the message if it's read or delivered.
 *
 * @param {boolean} isValid
 * @param {CometChatTheme} theme
 * @param {CometChat.BaseMessage} message
 * @param {MessageBubbleAlignment} alignment
 * @returns {CSSProperties}
 */
export const getStatusInfoViewStyle: (
  isValid: boolean,
  theme: CometChatTheme,
  message: CometChat.BaseMessage,
  alignment: MessageBubbleAlignment
) => CSSProperties = (
  isValid: boolean,
  theme: CometChatTheme,
  message: CometChat.BaseMessage,
  alignment: MessageBubbleAlignment
) => {
    const baseStyle = {
      display: "flex",
      alignItems: "end",
      padding: "1px 8px 8px",
      gap: "4px",
      marginTop: "0"
    };

    if (isValid) {
      return {
        ...baseStyle,
        justifyContent: "center",
        height: "fit-content",
        borderRadius: "22px",
        padding: "3px 5px",
        paddingTop: "2px",
        position: "relative",
        marginTop: "-22px",
        marginRight: "12px",
        background: theme.palette.getAccent500("dark"),
        width: "fit-content"
      };
    } else {
      if (message?.getType() === CometChatUIKitConstants.MessageTypes.text) {
        baseStyle['padding'] = '0px 8px 8px';
        baseStyle['marginTop'] = alignment === MessageBubbleAlignment.right ? '-6px' : '-5px'
      }

      return baseStyle;
    }
  };

/**
 * Style object for footerView wrapper which is visible at the bottom of the CometChatMessageBubble component.
It is a placeholder which accepts a custom view. By default, message Reactions are visible at the bottom for the message
received in a particular User/Group Chat.
 *
 * @param {MessageBubbleAlignment} alignment
 * @returns {CSSProperties}
 */
export const bubbleFooterViewWrapperStyle: (alignment: MessageBubbleAlignment) => CSSProperties = (
  alignment: MessageBubbleAlignment
): CSSProperties => {
  let justifyContent = "center";
  if (alignment === MessageBubbleAlignment.right) {
    justifyContent = "flex-end";
  } else if (alignment === MessageBubbleAlignment.left) {
    justifyContent = "flex-start";
  }

  return {
    display: "flex",
    justifyContent: justifyContent,
    width: "100%",
    boxSizing: "border-box",
  };
};

/**
 * Style object for time in headerView of CometChatMessageBubble which shows the time when the message was sent
in the particular user/group chat

 *
 * @param {(MessageListStyle | undefined)} messageListStyle
 * @param {CometChatTheme} theme
 * @returns {DateStyle}
 */
export const messageBubbleHeaderDateStyle: (
  messageListStyle: MessageListStyle | undefined,
  theme: CometChatTheme
) => DateStyle = (
  messageListStyle: MessageListStyle | undefined,
  theme: CometChatTheme
) => {
    return {
      textColor:
        messageListStyle?.TimestampTextColor || theme.palette.getAccent600(),
      textFont:
        messageListStyle?.TimestampTextFont ||
        fontHelper(theme.typography.caption3),
      padding: "0",
      display: "block"
    };
  };

/**
 * Style object for title in headerView of CometChatMessageBubble which shows the name
of the sender of the message in the particular user/group chat
 *
 * @param {CometChatTheme} theme
 * @returns {LabelStyle}
 */
export const bubbleHeaderTitleStyle: (theme: CometChatTheme, messageListStyle?: MessageListStyle) => LabelStyle = (theme: CometChatTheme, messageListStyle?: MessageListStyle) => {
  const defaultMessageListStyle: MessageListStyle = {
    ...tempMessageListStyle(theme),
    ...messageListStyle,
  };
  return {
    textFont: defaultMessageListStyle?.nameTextFont || fontHelper(theme.typography.caption2),
    textColor: defaultMessageListStyle?.nameTextColor || theme.palette.getAccent600(),
  };
};

/**
 * Generates the style object for the CometChatDate component, visible between the messages in CometChatMessageList as a separator for same-date messages.
 *
 * @param {(DateStyle | undefined)} dateSeparatorStyle
 * @param {CometChatTheme} theme
 * @returns {DateStyle}
 */
export const dateSeperatorStyle: (
  dateSeparatorStyle: DateStyle | undefined,
  theme: CometChatTheme
) => DateStyle = (
  dateSeparatorStyle: DateStyle | undefined,
  theme: CometChatTheme
) => {
    return new DateStyle({
      textFont:
        dateSeparatorStyle?.textFont || fontHelper(theme.typography.subtitle2),
      textColor: dateSeparatorStyle?.textColor || theme.palette.getAccent600(),
      background: dateSeparatorStyle?.background || "inherit",
      height: dateSeparatorStyle?.height || "100%",
      width: dateSeparatorStyle?.width || "100%",
      border:
        dateSeparatorStyle?.border || `1px solid ${theme.palette.getAccent100()}`,
      borderRadius: dateSeparatorStyle?.borderRadius || "8px",
    });
  };

/**
 * Provides the default style object for the CometChatMessageList component if the required styles are not provided by the parent component.
 *
 * @param {CometChatTheme} theme
 * @returns {MessageListStyle}
 */
export const tempMessageListStyle: (theme: CometChatTheme) => MessageListStyle = (theme: CometChatTheme) => {
  return new MessageListStyle({
    background: theme.palette.getBackground(),
    border: `none`,
    emptyStateTextFont: fontHelper(theme.typography.title1),
    emptyStateTextColor: theme.palette.getAccent600(),
    errorStateTextFont: fontHelper(theme.typography.title1),
    errorStateTextColor: theme.palette.getAccent600(),
    loadingIconTint: theme.palette.getAccent600(),
    nameTextFont: fontHelper(theme.typography.caption2),
    nameTextColor: theme.palette.getAccent600(),
    threadReplyTextFont: fontHelper(theme.typography.subtitle1),
    threadReplyIconTint: theme.palette.getAccent600(),
    threadReplyTextColor: theme.palette.getAccent600(),
    TimestampTextFont: fontHelper(theme.typography.caption2),
    TimestampTextColor: theme.palette.getAccent600(),
  });
};


/**
 * Generates the style object for the loading view visible in the center of the CometChatMessageList component until the messages for a specific User/Group are fetched.
 *
 * @param {(MessageListStyle | undefined)} messageListStyle
 * @param {CometChatTheme} theme
 * @returns {LoaderStyle}
 */
export const loadingViewStyle: (
  messageListStyle: MessageListStyle | undefined,
  theme: CometChatTheme
) => LoaderStyle = (
  messageListStyle: MessageListStyle | undefined,
  theme: CometChatTheme
) => {
    const defaultMessageListStyle: MessageListStyle = {
      ...tempMessageListStyle(theme),
      ...messageListStyle,
    };
    return {
      iconTint: defaultMessageListStyle.loadingIconTint,
    };
  };

/**
 * Generates the style object for the error view visible in the center of the CometChatMessageList component when there is an issue fetching the messages of a specific User/Group.
 *
 * @param {(MessageListStyle | undefined)} messageListStyle
 * @param {CometChatTheme} theme
 * @returns {LabelStyle}
 */
export const errorViewStyle: (
  messageListStyle: MessageListStyle | undefined,
  theme: CometChatTheme
) => LabelStyle = (
  messageListStyle: MessageListStyle | undefined,
  theme: CometChatTheme
) => {
    const defaultMessageListStyle: MessageListStyle = {
      ...tempMessageListStyle(theme),
      ...messageListStyle,
    };
    return {
      textFont: defaultMessageListStyle.errorStateTextFont,
      textColor: defaultMessageListStyle.errorStateTextColor,
    };
  };

/**
 * Generates the style object for the CometChatAvatar component, which includes both styling received from the parent component and default styling required in the CometChatAvatar component.
 *
 * @param {(AvatarStyle | undefined)} avatarStyle
 * @param {CometChatTheme} theme
 * @returns {AvatarStyle}
 */
export const messageAvatarStyle: (
  avatarStyle: AvatarStyle | undefined,
  theme: CometChatTheme
) => AvatarStyle = (
  avatarStyle: AvatarStyle | undefined,
  theme: CometChatTheme
) => {
    const defaultAvatarStyle = new AvatarStyle({
      borderRadius: "24px",
      width: "28px",
      height: "28px",
      border: "none",
      backgroundColor: theme.palette.getAccent700(),
      nameTextColor: theme.palette.getAccent900(),
      backgroundSize: "cover",
      nameTextFont: fontHelper(theme.typography.subtitle1),
      outerViewBorderWidth: "0",
      outerViewBorderRadius: "0",
      outerViewBorderColor: "",
      outerViewBorderSpacing: "0",
    });
    return { ...defaultAvatarStyle, ...avatarStyle };
  };

/**
 * Returns the default style object for CometChatDate wrapper, visible in the center between the messages in CometChatMessageList as a separator for same-day messages.
 *
 * @returns {CSSProperties}
 */
export const dateSeperatorWrapperStyle: () => CSSProperties = () => {
  return {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: "16px",
  };
};

/**
 * Provides the default style object for the CometChatAvatar visible in the leadingView of CometChatMessageBubble, showing the sender's avatar.
 *
 * @type {AvatarStyle}
 */
export const defaultAvatarStyle: AvatarStyle = {
  borderRadius: "24px",
  width: "36px",
  height: "36px",
};
/**
 * Returns the default style object for CometChatMessageList if the style is not received from the parent.
 *
 * @type {MessageListStyle}
 */
export const defaultMessageListStyle: MessageListStyle = {
  nameTextFont: "600 15px Inter, sans-serif",
  nameTextColor: "white",
  TimestampTextFont: "",
  TimestampTextColor: "",
  threadReplyTextFont: "",
  threadReplyIconTint: "",
  threadReplyTextColor: "",
  emptyStateTextFont: "700 22px Inter, sans-serif",
  emptyStateTextColor: "#bcbcbc",
  errorStateTextFont: "700 22px Inter, sans-serif",
  errorStateTextColor: "#bcbcbc",
  loadingIconTint: "grey",
};

/**
 * Provides the default style object for the CometChatMessageBubble wrapper div.
 *
 * @param {MessageBubbleAlignment} alignment
 * @returns {CSSProperties}
 */
export const bubbleStyle: (
  alignment: MessageBubbleAlignment
) => CSSProperties = (
  alignment: MessageBubbleAlignment
): CSSProperties => {
    let justifyContent = "center";
    if (alignment === MessageBubbleAlignment.right) {
      justifyContent = "flex-end";
    } else if (alignment === MessageBubbleAlignment.left) {
      justifyContent = "flex-start";
    }

    return {
      display: "flex",
      justifyContent: justifyContent,
      width: "100%",
      marginBottom: "16px",
    };
  };

/**
 * Supplies the default style object for the CometChatMessageBubble container.
 *
 * @type {CSSProperties}
 */
export const defaultMessageListBubbleStyle: CSSProperties = {
  padding: "0px 0px 0px 15px",
  overflow: "hidden",
  display: "flex",
  justifyContent: "flex-end",
  marginRight: "8px",
  boxSizing: "border-box"
};

/**
 * Returns the default style for the CometChatList component, which renders a list of messages for a specific user/group.
 *
 * @returns {ListStyle}
 */
export const getListStyle: () => ListStyle = () => {
  return new ListStyle({
    height: "100%",
    width: "100%",
    background: "inherit",
  });
};

/**
 * Provides the default style for the CometChatAvatar component, which renders an Avatar about the reactor in the Reactions list.
 *
 * @param {CometChatTheme} theme
 * @returns {AvatarStyle}
 */
export const getReactionListAvatarStyle: (theme: CometChatTheme) => AvatarStyle = (theme: CometChatTheme) => {
  return new AvatarStyle({
    borderRadius: "50%",
    width: "35px",
    height: "35px",
    border: "none",
    backgroundColor: theme.palette.getAccent700(),
    nameTextColor: theme.palette.getAccent900(),
    backgroundSize: "cover",
    nameTextFont: fontHelper(theme.typography.subtitle1),
    outerViewBorderWidth: "0",
    outerViewBorderRadius: "0",
    outerViewBorderColor: "",
    outerViewBorderSpacing: "0",
  });
}

/**
 * Generates the default style for the CometChatListItem component, which renders details about the reaction in the list.
 *
 * @param {CometChatTheme} theme
 * @returns {ListItemStyle}
 */
export const getReactionListItemStyle: (theme: CometChatTheme) => ListItemStyle = (theme: CometChatTheme) => {
  return new ListItemStyle({
    background: "transparent",
    activeBackground: "transparent",
    hoverBackground: "transparent",
    titleFont: fontHelper(theme.typography.subtitle1),
    titleColor: theme.palette.getAccent(),
    separatorColor: theme.palette.getAccent100(),
  });
}

/**
 * Provides the default style for the CometChatReactionList component, which shows a list of reactions on a specific reaction along with the reactor's details.
 *
 * @param {CometChatTheme} theme
 * @returns {ReactionListStyle}
 */
export const getReactionListStyle: (theme: CometChatTheme) => ReactionListStyle = (theme: CometChatTheme) => {
  return new ReactionListStyle({
    width: "320px",
    height: "300px",
    borderRadius: "12px",
    background: theme.palette.getAccent900(),
    border: "none",
    errorIconTint: theme.palette.getAccent400(),
    loadingIconTint: theme.palette.getAccent400(),
    sliderEmojiCountFont: fontHelper(theme.typography.subtitle2),
    sliderEmojiFont: fontHelper(theme.typography.subtitle1),
    subtitleTextColor: theme.palette.getAccent500(),
    subtitleTextFont: fontHelper(theme.typography.subtitle2),
    tailViewFont: fontHelper(theme.typography.title1),
    dividerTint: theme.palette.getAccent100(),
    sliderEmojiCountColor: theme.palette.getAccent500(),
    activeEmojiBackground: theme.palette.getAccent100()
  })
}

/**
 * Default styling for CometChatReactionInfo component. If the configuration styling is not passed.
 *
 * @param {CometChatTheme} theme
 * @param {ReactionInfoConfiguration} config
 * @returns {ReactionInfoStyle}
 */
export const getReactionInfoStyle: (theme: CometChatTheme, config: ReactionInfoConfiguration) => ReactionInfoStyle = (theme: CometChatTheme, config: ReactionInfoConfiguration) => {
  return new ReactionInfoStyle({
    background: config?.reactionInfoStyle?.background || theme.palette.getAccent("light"),
    border: config?.reactionInfoStyle?.border || "none",
    borderRadius: config?.reactionInfoStyle?.borderRadius || "12px",
    errorIconTint: config?.reactionInfoStyle?.errorIconTint || theme.palette.getBackground("light"),
    loadingIconTint: config?.reactionInfoStyle?.loadingIconTint || theme.palette.getBackground("light"),
    namesColor: config?.reactionInfoStyle?.namesColor || theme.palette.getBackground("light"),
    namesFont: config?.reactionInfoStyle?.namesFont || fontHelper(theme.typography.subtitle2),
    reactedTextColor: config?.reactionInfoStyle?.reactedTextColor || theme.palette.getAccent700("dark"),
    reactedTextFont: config?.reactionInfoStyle?.reactedTextFont || fontHelper(theme.typography.subtitle2),
    reactionFontSize: config?.reactionInfoStyle?.reactionFontSize || "37px"
  });
}

/**
 * Default styleing for CometChatReactions component wrapper.
 *
 * @param {MessageBubbleAlignment} alignment
 * @returns {CSSProperties}
 */
export const getReactionViewStyle: (alignment: MessageBubbleAlignment) => CSSProperties = (alignment: MessageBubbleAlignment) => {
  return {
    width: "100%",
    padding: "5px 0px",
    boxSizing: "border-box",
    display: "flex",
    marginTop: "-9px",
    justifyContent: alignment === MessageBubbleAlignment.left ? "flex-start" : "flex-end",
  }
}
/**
 * Returns the default style for the statusInfoView  wrapper in CometChatMessageBubble.
 *
 * @returns {CSSProperties}
 */
export const getStatusInfoStyle: () => CSSProperties = () => {
  return {
    position: "relative", display: "flex", justifyContent: "flex-end"
  }
}
/**
 * Generates the default style for the CometChatReactions component wrapper, which shows a list of reactions on a specific message, visible in the footer view of the message bubble.
 *
 * @param {ReactionsStyle} [reactionsStyle]
 * @param {CometChatTheme} theme
 * @returns {ReactionsStyle}
 */
export const getReactionsStyle: (reactionsStyle: ReactionsStyle | undefined, theme: CometChatTheme) => ReactionsStyle = (reactionsStyle: ReactionsStyle = {}, theme: CometChatTheme) => {
  return new ReactionsStyle({
    height: reactionsStyle?.height || "100%",
    width: reactionsStyle?.width || "fit-content",
    border: reactionsStyle?.border || "none",
    borderRadius: reactionsStyle?.borderRadius || "0",
    background: reactionsStyle?.background || "transparent",
    activeReactionBackground: reactionsStyle?.activeReactionBackground || theme.palette.getPrimary150(),
    reactionBackground: reactionsStyle?.reactionBackground || theme.palette.getAccent100(),
    reactionBorder: reactionsStyle?.reactionBorder || `none`,
    activeReactionBorder: reactionsStyle?.activeReactionBorder || `1px solid ${theme.palette.getPrimary500()}`,
    reactionBorderRadius: reactionsStyle?.reactionBorderRadius || "20px",
    activeReactionCountTextColor: reactionsStyle?.activeReactionCountTextColor || theme.palette.getAccent(),
    activeReactionCountTextFont: reactionsStyle?.activeReactionCountTextFont || fontHelper(theme.typography.caption1),
    reactionCountTextFont: reactionsStyle?.reactionCountTextFont || fontHelper(theme.typography.caption1),
    reactionCountTextColor: reactionsStyle?.reactionCountTextColor || theme.palette.getAccent(),
    reactionBoxShadow: reactionsStyle?.reactionBoxShadow || "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    reactionEmojiFont: reactionsStyle?.reactionEmojiFont || fontHelper(theme.typography.subtitle1),
    baseReactionBackground: reactionsStyle?.baseReactionBackground || theme.palette.getBackground(),
  })
}
