import { AvatarStyle, ListItemStyle } from "@cometchat/uikit-elements";
import { CallLogParticipantsStyle, ListStyle } from "@cometchat/uikit-shared";
import { CometChatTheme, fontHelper } from "@cometchat/uikit-resources";

export function getButtonContainerStyle(): React.CSSProperties {
  return {
    marginRight: "15px",
  };
}

export function getBackButtonStyle(
  theme: CometChatTheme,
  style: CallLogParticipantsStyle
) {
  return {
    height: "24px",
    width: "24px",
    border: "none",
    borderRadius: "0",
    background: "transparent",
    buttonIconTint: style?.backIconTint || theme.palette.getPrimary(),
  };
}

export function getListStyle(
  theme: CometChatTheme,
  callLogsStyle: CallLogParticipantsStyle
): ListStyle {
  return new ListStyle({
    height: "90%",
    titleTextFont:
      callLogsStyle?.titleFont || fontHelper(theme.typography.title1),
    titleTextColor: callLogsStyle?.titleColor || theme.palette.getAccent(),
  });
}


export function getAvatarStyle(
  theme: CometChatTheme,
  avatarStyle?: AvatarStyle
) {
  return new AvatarStyle({
    borderRadius: avatarStyle?.borderRadius || "24px",
    width: avatarStyle?.width || "36px",
    height: avatarStyle?.height || "36px",
    border: avatarStyle?.border || "none",
    backgroundColor:
      avatarStyle?.backgroundColor || theme.palette.getAccent700(),
    nameTextColor: avatarStyle?.nameTextColor || theme.palette.getAccent900(),
    backgroundSize: avatarStyle?.backgroundSize || "cover",
    nameTextFont:
      avatarStyle?.nameTextFont || fontHelper(theme.typography.subtitle1),
    outerViewBorderWidth: avatarStyle?.outerViewBorderWidth || "",
    outerViewBorderSpacing: avatarStyle?.outerViewBorderSpacing || "",
    outerViewBorderColor: avatarStyle?.outerViewBorderColor || "",
    outerViewBorderRadius: avatarStyle?.outerViewBorderRadius || "",
  });
}

export function getListItemStyle(
  theme: CometChatTheme,
  listItemStyle?: ListItemStyle
) {
  return new ListItemStyle({
    height: listItemStyle?.height || "45px",
    width: listItemStyle?.width || "100%",
    background: listItemStyle?.background || theme.palette.getBackground(),
    activeBackground:
      listItemStyle?.activeBackground || theme.palette.getAccent100(),
    borderRadius: listItemStyle?.borderRadius || "0",
    titleFont: listItemStyle?.titleFont || fontHelper(theme.typography.title2),
    titleColor: listItemStyle?.titleColor || theme.palette.getAccent(),
    border: listItemStyle?.border || "none",
    separatorColor:
      listItemStyle?.separatorColor || theme.palette.getAccent200(),
    hoverBackground:
      listItemStyle?.hoverBackground || theme.palette.getAccent50(),
    padding: listItemStyle?.padding || "0",
  });
}

export function getSubtitleStyle(
  theme: CometChatTheme,
  style: CallLogParticipantsStyle
): React.CSSProperties {
  return {
    display: "flex",
    color: style?.callStatusColor || theme.palette.getAccent600(),
    font: style?.callStatusFont || fontHelper(theme.typography.caption1),
  };
}

export function getCallDateStyle(
  theme: CometChatTheme,
  style: CallLogParticipantsStyle
) {
  return {
    textColor: style?.dateTextColor || theme.palette.getAccent500(),
    textFont: style?.dateTextFont || fontHelper(theme.typography.caption1),
    font: style?.dateTextFont || fontHelper(theme.typography.caption1),
    color: style?.dateTextColor || theme.palette.getAccent500(),
    background: "transparent",
    padding: "3px 10px",
  };
}

export function getContainerStyle(
  theme: CometChatTheme,
  callLogsStyle: CallLogParticipantsStyle
): React.CSSProperties {
  return {
    height: callLogsStyle?.height || "100%",
    width: callLogsStyle?.width || "100%",
    background: callLogsStyle?.background || theme.palette.getBackground(),
    borderRadius: callLogsStyle?.borderRadius || "0",
    border: callLogsStyle?.border || "none",
  };
}

export function getTitleStyle(
  theme: CometChatTheme,
  style: CallLogParticipantsStyle
) {
  return {
    font: style?.titleFont || fontHelper(theme.typography.text1),
    color: style?.titleColor || theme.palette.getAccent(),
    background: "transparent",
  };
}
