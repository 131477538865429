import {
  LabelStyle,
  LoaderStyle,
  SearchInputStyle,
} from "@cometchat/uikit-elements";

import { CSSProperties } from "react";
import { ListStyle } from "@cometchat/uikit-shared";
import { TitleAlignment } from "@cometchat/uikit-resources";

export function listWrapperStyle(listStyle: ListStyle | null): CSSProperties {
  return {
    boxSizing: "border-box",
    width: listStyle?.width || "100%",
    height: listStyle?.height || "100%",
    border: listStyle?.border || "none",
    borderRadius: listStyle?.borderRadius || "0",
    background: listStyle?.background || "inherit",
    boxShadow: listStyle?.boxShadow,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  };
}

export function headerStyle(emptyTitle: boolean): CSSProperties {
  return {
    flexShrink: "0",
    padding: emptyTitle ? "0px 12px" : "19px 16px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box"
  };
}

export function titleStyle(
  listStyle: ListStyle | null,
  titleAlignment: TitleAlignment,
  emptyTitle?: Boolean
): CSSProperties {
  return {
    textAlign: titleAlignment === TitleAlignment.left ? "left" : "center",
    font: listStyle?.titleTextFont || "700 22px Inter, sans-serif",
    color: listStyle?.titleTextColor || "rgb(20, 20, 20)",
    boxSizing: "border-box",
  };
}

export const searchStyle = {
  padding: "16px",
  margin: "-16px -16px 0 -16px",
} as CSSProperties;

export function searchInputStyle(
  listStyle: ListStyle | null
): SearchInputStyle {
  //   Property not set - boxShadow? : string;

  return {
    border: listStyle?.searchBorder || "none",
    borderRadius: listStyle?.searchBorderRadius || "8px",
    width: "100%",
    height: "32px",
    background: listStyle?.searchBackground || "rgb(20, 20, 20, 0.04)",
    searchTextFont: listStyle?.searchTextFont || "400 15px Inter, sans-serif",
    searchTextColor: listStyle?.searchTextColor || "rgb(20, 20, 20)",
    placeholderTextFont:
      listStyle?.searchPlaceholderTextFont || "400 15px Inter, sans-serif",
    placeholderTextColor:
      listStyle?.searchPlaceholderTextColor || "rgb(20, 20, 20, 0.46)",
    searchIconTint: listStyle?.searchIconTint || "rgb(20, 20, 20, 0.46)",
  };
}

export function listItemContainerStyle(): CSSProperties {
  return {
    overflow: "hidden auto",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    padding: "0 16px",
  };
}

export function viewContainerStyle(): CSSProperties {
  return {
    height: "calc(100% - 1px)",
    overflow: "auto",
  };
}

export function defaultViewStyle(): CSSProperties {
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  };
}

export function customViewStyle(): CSSProperties {
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
}

export function loaderStyle(listStyle: ListStyle | null): LoaderStyle {
  return {
    iconTint: listStyle?.loadingIconTint || "rgb(20, 20, 20, 0.58)",
  };
}

export function sectionHeaderStyle(listStyle: ListStyle | null): CSSProperties {
  return {
    font: listStyle?.sectionHeaderTextFont || "500 12px Inter, sans-serif",
    color: listStyle?.sectionHeaderTextColor || "rbg(20, 20, 20, 0.46)",
    textAlign: "left",
    padding: "8px 0",
  };
}

export function errorLabelStyle(listStyle: ListStyle | null): LabelStyle {
  return {
    textFont: listStyle?.errorStateTextFont || "700 22px Inter, sans-serif",
    textColor: listStyle?.errorStateTextColor || "rgb(20, 20, 20, 0.33)",
  };
}

export function emptyLabelStyle(listStyle: ListStyle | null): LabelStyle {
  return {
    textFont: listStyle?.emptyStateTextFont || "700 22px Inter, sans-serif",
    textColor: listStyle?.emptyStateTextColor || "rgb(20, 20, 20, 0.33)",
  };
}

export function intersectionObserverBottomTargetDivStyle(): CSSProperties {
  return {
    height: "1px",
    border: "1px solid transparent"
  };
}
