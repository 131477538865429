import { CardMessage, ChatSdkEventInitializer, CometChatLocalize, CometChatMessageEvents, CustomInteractiveMessage, FormMessage, MessageStatus, SchedulerMessage } from "@cometchat/uikit-resources";
import { CometChatSoundManager, CometChatUIKitCalls, CometChatUIKitLoginListener, CometChatUIKitSharedSettings, CometChatUIKitUtility, InteractiveMessageUtils, UIKitSettings } from '@cometchat/uikit-shared';

import { AIAssistBotExtension } from "../../AI/AIAssistBot/AIAssistBotExtension";
import { AIConversationStarterExtension } from "../../AI/AIConversationStarter/AIConversationStarterExtension";
import { AIConversationSummaryExtension } from "../../AI/AIConversationSummary/AIConversationSummaryExtension";
import { AIExtensionDataSource } from "../Framework/AIExtensionDataSource";
import { AISmartRepliesExtension } from "../../AI/AISmartReplies/AISmartRepliesExtension";
import { CallingExtension } from "../../Calling/CallingExtension";
import { ChatConfigurator } from "../Framework/ChatConfigurator";
import { CollaborativeDocumentExtension } from "../../Extensions/CollaborativeDocument/CollaborativeDocumentExtension";
import { CollaborativeWhiteboardExtension } from "../../Extensions/CollaborativeWhiteboard/CollaborativeWhiteboardExtension";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { ExtensionsDataSource } from "../Framework/ExtensionsDataSource";
import { ImageModerationExtension } from "../../Extensions/ImageModeration/ImageModerationExtension";
import { LinkPreviewExtension } from "../../Extensions/LinkPreview/LinkPreviewExtension";
import { MessageTranslationExtension } from "../../Extensions/MessageTranslation/MessageTranslationExtension";
import { PollsExtension } from "../../Extensions/Polls/PollsExtension";
import { SmartReplyExtension } from "../../Extensions/SmartReplies/SmartRepliesExtension";
import { StickersExtension } from "../../Extensions/Stickers/StickersExtension";
import { TextModeratorExtension } from "../../Extensions/TextModerator/TextModeratorExtension";
import { ThumbnailGenerationExtension } from "../../Extensions/ThumbnailGeneration/ThumbnailGenerationExtension";

class CometChatUIKit {
    static uiKitSettings: UIKitSettings | null;
    static SoundManager: typeof CometChatSoundManager = CometChatSoundManager;
    static Localize: typeof CometChatLocalize = CometChatLocalize;
    static conversationUpdateSettings: CometChat.ConversationUpdateSettings;
    static init(uiKitSettings: UIKitSettings | null): Promise<Object> | undefined {
        CometChatUIKit.uiKitSettings = uiKitSettings
        if (!CometChatUIKit.checkAuthSettings()) return undefined;
        const appSettingsBuilder = new CometChat.AppSettingsBuilder();
        if (uiKitSettings!.getRoles()) {
            appSettingsBuilder.subscribePresenceForRoles(uiKitSettings!.getRoles());
        } else if (uiKitSettings!.getSubscriptionType() === "ALL_USERS") {
            appSettingsBuilder.subscribePresenceForAllUsers();
        } else if (uiKitSettings!.getSubscriptionType() === "FRIENDS") {
            appSettingsBuilder.subscribePresenceForFriends();
        }
        appSettingsBuilder.autoEstablishSocketConnection(uiKitSettings!.isAutoEstablishSocketConnection());
        appSettingsBuilder.setRegion(uiKitSettings!.getRegion());
        appSettingsBuilder.overrideAdminHost(uiKitSettings!.getAdminHost());
        appSettingsBuilder.overrideClientHost(uiKitSettings!.getClientHost());

        const appSettings = appSettingsBuilder.build();
        if (CometChat.setSource) {
            CometChat.setSource("uikit-v4", "web", "reactjs");
        }
        CometChatUIKitSharedSettings.uikitSettings = CometChatUIKit.uiKitSettings;
        return new Promise((resolve, reject) => {
            (<any>window).CometChatUiKit = {
                name: "@cometchat/chat-uikit-react",
                version: "4.3.21",
            };
            CometChat.init(uiKitSettings?.appId, appSettings).then(() => {
                CometChat.getLoggedinUser().then((user: CometChat.User | null) => {
                    if (user) {
                        CometChatUIKitLoginListener.setLoggedInUser(user);
                        ChatConfigurator.init();
                        this.initiateAfterLogin()
                    }
                    return resolve(user!)
                }).catch((error: CometChat.CometChatException) => {
                    console.log(error)
                    return reject(error)
                })
            })
                .catch((error: CometChat.CometChatException) => {
                    return reject(error)
                })
        });
    }

    static defaultExtensions: ExtensionsDataSource[] = [
        new StickersExtension(),
        new SmartReplyExtension(),
        new CollaborativeWhiteboardExtension(),
        new CollaborativeDocumentExtension(),
        new MessageTranslationExtension(),
        new TextModeratorExtension(),
        new ThumbnailGenerationExtension(),
        new LinkPreviewExtension(),
        new PollsExtension(),
        new ImageModerationExtension()
    ]

    static defaultAIFeatures: AIExtensionDataSource[] = [
        new AISmartRepliesExtension(),
        new AIConversationStarterExtension(),
        new AIConversationSummaryExtension(),
        new AIAssistBotExtension()
    ]

    static enableCalling() {
        try {
            if (CometChatUIKitCalls) {
                const callAppSetting = new CometChatUIKitCalls.CallAppSettingsBuilder()
                    .setAppId(CometChatUIKit.uiKitSettings?.appId)
                    .setRegion(CometChatUIKit.uiKitSettings?.region)
                    .build();
                CometChatUIKitCalls.init(callAppSetting).then(
                    () => {
                        new CallingExtension().enable()
                    },
                    (error: any) => {
                        console.log('CometChatCalls initialization failed with error:', error);
                    },
                );
            }
        } catch (e) {
            console.log(e);
        }
    }

    private static initiateAfterLogin() {


        if (CometChatUIKit.uiKitSettings != null) {
            CometChat.getConversationUpdateSettings().then((res: CometChat.ConversationUpdateSettings) => {
                this.conversationUpdateSettings = res;
            })
            let extensionList: ExtensionsDataSource[] = this.uiKitSettings?.extensions || this.defaultExtensions;
            ChatSdkEventInitializer.attachListeners();
            CometChatUIKitLoginListener.attachListener();
            let aiFeaturesList: AIExtensionDataSource[] = this.uiKitSettings?.aiFeatures || this.defaultAIFeatures;

            if (extensionList.length > 0) {
                extensionList.forEach((extension: ExtensionsDataSource) => {
                    extension?.enable();
                });
            }

            if (aiFeaturesList.length > 0) {
                aiFeaturesList.forEach((aiFeatures: AIExtensionDataSource) => {
                    aiFeatures.enable();
                })
            }

            this.enableCalling();
        }
    }

    static login(uid: string): Promise<CometChat.User> {

        return new Promise((resolve, reject) => {

            if (!CometChatUIKit.checkAuthSettings()) return reject("uiKitSettings not available");
            CometChatUIKit.getLoggedinUser()?.then((user) => {
                if (user) {
                    CometChatUIKitLoginListener.setLoggedInUser(user);
                    this.initiateAfterLogin();
                    return resolve(user);
                } else {
                    CometChat.login(uid, CometChatUIKit.uiKitSettings!.authKey!).then((user: CometChat.User) => {
                        CometChatUIKitLoginListener.setLoggedInUser(user);
                        ChatConfigurator.init();
                        CometChatUIKitLoginListener.setLoggedInUser(user);
                        this.initiateAfterLogin();
                        return resolve(user);
                    }).catch((error: CometChat.CometChatException) => {
                        return reject(error);
                    })
                }
            });
        });
    }

    static loginWithAuthToken(authToken: string): Promise<CometChat.User> {

        return new Promise((resolve, reject) => {

            if (!CometChatUIKit.checkAuthSettings()) return reject("uiKitSettings not available");
            CometChat.login(authToken).then((user: CometChat.User) => {
                CometChatUIKitLoginListener.setLoggedInUser(user);
                ChatConfigurator.init();
                this.initiateAfterLogin();
                return resolve(user);
            }).catch((error: CometChat.CometChatException) => {
                return reject(error);
            })
        });
    }

    static getLoggedinUser(): Promise<CometChat.User | null> {
        return new Promise((resolve, reject) => {
            if (!CometChatUIKit.checkAuthSettings()) return reject("uiKitSettings not available");

            CometChat.getLoggedinUser().then((user: CometChat.User | null) => {
                if (user) {
                    CometChatUIKitLoginListener.setLoggedInUser(user);
                }
                return resolve(user);
            }).catch((error: CometChat.CometChatException) => {
                return reject(error);
            })
        })
    }

    static createUser(user: CometChat.User): Promise<CometChat.User> {

        return new Promise((resolve, reject) => {
            if (!CometChatUIKit.checkAuthSettings()) return reject("uiKitSettings not available");
            CometChat.createUser(user, CometChatUIKit.uiKitSettings!.authKey!).then((user: CometChat.User) => {
                return resolve(user);
            }).catch((error: CometChat.CometChatException) => {
                return reject(error);
            })
        });
    }

    static updateUser(user: CometChat.User): Promise<CometChat.User> {

        return new Promise((resolve, reject) => {
            if (!CometChatUIKit.checkAuthSettings()) return reject("uiKitSettings not available");
            CometChat.updateUser(user, CometChatUIKit.uiKitSettings!.authKey!).then((user: CometChat.User) => {
                return resolve(user);
            }).catch((error: CometChat.CometChatException) => {
                return reject(error);
            })
        });
    }

    static logout(): Promise<Object> {

        return new Promise((resolve, reject) => {

            if (!CometChatUIKit.checkAuthSettings()) {
                const error = {
                    code: "ERROR_UIKIT_NOT_INITIALISED",
                    message: "UIKItSettings not available"
                }
                return reject(error);
            }
            CometChat.logout().then((message: object) => {
                CometChatUIKitLoginListener.removeLoggedInUser();
                return resolve(message);
            }).catch((error: CometChat.CometChatException) => {
                return reject(error);
            })
        });
    }

    static checkAuthSettings(): boolean {
        if (CometChatUIKit.uiKitSettings == null) {
            return false;
        }

        if (CometChatUIKit.uiKitSettings!.appId == null) {
            return false;
        }

        return true;
    }


    /**
     * Sends a form message and emits events based on the message status.
     * @param message - The form message to be sent.
     * @param disableLocalEvents - A boolean indicating whether to disable local events or not. Default value is false.
     */
    static sendFormMessage(
        message: FormMessage,
        disableLocalEvents: boolean = false
    ) {
        message.setSentAt(CometChatUIKitUtility.getUnixTimestamp());
        if (!message?.getMuid()) {
            message.setMuid(CometChatUIKitUtility.ID());
        }
        if (!disableLocalEvents) {
            CometChatMessageEvents.ccMessageSent.next({
                message: message,
                status: MessageStatus.inprogress,
            });
        }
        CometChat.sendInteractiveMessage(message)
            .then((message: CometChat.BaseMessage) => {
                let interactiveMessage: FormMessage = InteractiveMessageUtils.convertInteractiveMessage(
                    message as CometChat.InteractiveMessage
                ) as FormMessage;
                if (!disableLocalEvents) {
                    CometChatMessageEvents.ccMessageSent.next({
                        message: interactiveMessage,
                        status: MessageStatus.success,
                    });
                }
            })
            .catch((error: CometChat.CometChatException) => {
                message.setMetadata({ error });
                if (!disableLocalEvents) {
                    CometChatMessageEvents.ccMessageSent.next({
                        message: message,
                        status: MessageStatus.error,
                    });
                }
            });
    }

    static sendCardMessage(message: CardMessage, disableLocalEvents: boolean = false) {
        message.setSentAt(CometChatUIKitUtility.getUnixTimestamp());
        if (!message?.getMuid()) {
            message.setMuid(CometChatUIKitUtility.ID());
        }
        if (!disableLocalEvents) {
            CometChatMessageEvents.ccMessageSent.next({
                message: message,
                status: MessageStatus.inprogress,
            });
        }

        CometChat.sendInteractiveMessage(message)
            .then((message: CometChat.BaseMessage) => {
                let interactiveMessage: CardMessage = InteractiveMessageUtils.convertInteractiveMessage(
                    message as CometChat.InteractiveMessage
                ) as CardMessage
                if (!disableLocalEvents) {
                    CometChatMessageEvents.ccMessageSent.next({
                        message: interactiveMessage,
                        status: MessageStatus.success,
                    });
                }
            })
            .catch((error: CometChat.CometChatException) => {
                message.setMetadata({ error });
                if (!disableLocalEvents) {
                    CometChatMessageEvents.ccMessageSent.next({
                        message: message,
                        status: MessageStatus.error,
                    });
                }
            });
    }

    static sendCustomInteractiveMessage(message: CustomInteractiveMessage, disableLocalEvents: boolean = false) {
        message.setSentAt(CometChatUIKitUtility.getUnixTimestamp());
        if (!message?.getMuid()) {
            message.setMuid(CometChatUIKitUtility.ID());
        }
        if (!disableLocalEvents) {
            CometChatMessageEvents.ccMessageSent.next({
                message: message,
                status: MessageStatus.inprogress,
            });
        }

        CometChat.sendInteractiveMessage(message)
            .then((message: CometChat.BaseMessage) => {
                let interactiveMessage: CustomInteractiveMessage = InteractiveMessageUtils.convertInteractiveMessage(
                    message as CometChat.InteractiveMessage
                ) as CustomInteractiveMessage
                if (!disableLocalEvents) {
                    CometChatMessageEvents.ccMessageSent.next({
                        message: interactiveMessage,
                        status: MessageStatus.success,
                    });
                }
            })
            .catch((error: CometChat.CometChatException) => {
                message.setMetadata({ error });
                if (!disableLocalEvents) {
                    CometChatMessageEvents.ccMessageSent.next({
                        message: message,
                        status: MessageStatus.error,
                    });
                }
            });
    }

    static sendCustomMessage(message: CometChat.CustomMessage) {

        return new Promise((resolve, reject) => {
            message.setSentAt(CometChatUIKitUtility.getUnixTimestamp());
            if (!message?.getMuid()) {
                message.setMuid(CometChatUIKitUtility.ID());
            }
            CometChatMessageEvents.ccMessageSent.next({ message: message, status: MessageStatus.inprogress });

            CometChat.sendCustomMessage(message).then((message: CometChat.BaseMessage) => {
                CometChatMessageEvents.ccMessageSent.next({ message: message, status: MessageStatus.success });
                return resolve(message);
            }).catch((error: CometChat.CometChatException) => {
                message.setMetadata({ error })
                CometChatMessageEvents.ccMessageSent.next({ message: message, status: MessageStatus.error });
                return reject(error);
            })
        });
    }

    static sendTextMessage(message: CometChat.TextMessage) {

        return new Promise((resolve, reject) => {
            message.setSentAt(CometChatUIKitUtility.getUnixTimestamp());
            if (!message?.getMuid()) {
                message.setMuid(CometChatUIKitUtility.ID());
            }
            CometChatMessageEvents.ccMessageSent.next({ message: message, status: MessageStatus.inprogress });

            CometChat.sendMessage(message).then((message: CometChat.BaseMessage) => {
                CometChatMessageEvents.ccMessageSent.next({ message: message, status: MessageStatus.success });
                return resolve(message);
            }).catch((error: CometChat.CometChatException) => {
                message.setMetadata({ error })
                CometChatMessageEvents.ccMessageSent.next({ message: message, status: MessageStatus.error });
                return reject(error);
            })
        });
    }

    static sendMediaMessage(message: CometChat.MediaMessage) {
        message.setSentAt(CometChatUIKitUtility.getUnixTimestamp());
        if (!message?.getMuid()) {
            message.setMuid(CometChatUIKitUtility.ID());
        }
        return new Promise((resolve, reject) => {
            CometChatMessageEvents.ccMessageSent.next({ message: message, status: MessageStatus.inprogress });

            CometChat.sendMediaMessage(message).then((message: CometChat.BaseMessage) => {
                CometChatMessageEvents.ccMessageSent.next({ message: message, status: MessageStatus.success });
                return resolve(message);
            }).catch((error: CometChat.CometChatException) => {
                message.setMetadata({ error })
                CometChatMessageEvents.ccMessageSent.next({ message: message, status: MessageStatus.error });
                return reject(error);
            })
        })

    }


    static sendSchedulerMessage(message: SchedulerMessage, disableLocalEvents: boolean = false) {
        return new Promise((resolve, reject) => {
            if (!message?.getMuid()) {
                message.setMuid(CometChatUIKitUtility.ID());
            }
            message.setSentAt(CometChatUIKitUtility.getUnixTimestamp());
            if (!disableLocalEvents) {
                CometChatMessageEvents.ccMessageSent.next({
                    message: message,
                    status: MessageStatus.inprogress,
                });
            }

            CometChat.sendInteractiveMessage(message)
                .then((message: CometChat.BaseMessage) => {
                    let interactiveMessage: SchedulerMessage = InteractiveMessageUtils.convertInteractiveMessage(
                        message as CometChat.InteractiveMessage
                    ) as SchedulerMessage
                    if (!disableLocalEvents) {
                        CometChatMessageEvents.ccMessageSent.next({
                            message: interactiveMessage,
                            status: MessageStatus.success,
                        });
                    }
                    resolve(message);
                })
                .catch((error: CometChat.CometChatException) => {
                    message.setMetadata({ error });
                    if (!disableLocalEvents) {
                        CometChatMessageEvents.ccMessageSent.next({
                            message: message,
                            status: MessageStatus.error,
                        });
                    }
                    reject(error);
                });
        });
    }

    static getDataSource() {
        return ChatConfigurator.getDataSource();
    }

}

export { CometChatUIKit };
