import { BaseStyle } from "@cometchat/uikit-shared";

/**
 * TabsStyle
 *
 * @property {string} height - The height of the component.
 * @property {string} width - The width of the component.
 * @property {string} border - The border of the component.
 * @property {string} borderRadius - The border radius of the component.
 * @property {string} background - The background color of the component.
 * @property {string} tabListHeight - The height of the tab list.
 * @property {string} tabListWidth - The width of the tab list.
 * @property {string} tabListBorder - The border of the tab list.
 * @property {string} tabListBorderRadius - The border radius of the tab list.
 * @property {string} tabListBackground - The background color of the tab list.
 * @property {string} tabListBoxShadow - The box shadow of the tab list.
 * @property {string} tabListPadding - The padding of the tab list.
 * @property {string} tabPaneWidth - The width of the tab pane.
 * @property {string} tabPaneHeight - The height of the tab pane.
 */
 export class TabsStyle extends BaseStyle {
    tabListHeight?: string = "100%";
    tabListWidth?: string = "100%";
    tabListBorder?: string = "0 none";
    tabListBorderRadius?: string = "0";
    tabListBackground?: string = "transparent";
    tabListBoxShadow?: string = "0";
    tabListPadding?:string = "0";
    tabPaneWidth?: string = "100%";
    tabPaneHeight?: string = "100%";
    constructor(props: Partial<TabsStyle>) {
        super({})
        Object.assign(this, props);
    }
}