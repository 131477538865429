import { CSSProperties, useContext } from 'react';
import { CometChatUsers } from '../CometChatUsers';
import { CometChatGroupMembers } from '../CometChatGroupMembers';
import { UserMemberListType, UserPresencePlacement } from "@cometchat/uikit-resources";
import { AvatarStyle } from "@cometchat/uikit-elements";
import { CometChatThemeContext } from '../CometChatThemeContext';


export interface IMentionsProps {
  userMemberListType?: UserMemberListType;
  onItemClick?: (user: CometChat.User | CometChat.GroupMember) => void;
  listItemView?: (item?: CometChat.User | CometChat.GroupMember) => JSX.Element
  avatarStyle?: AvatarStyle;
  statusIndicatorStyle?: CSSProperties;
  searchKeyword?: string;
  group?: CometChat.Group;
  subtitleView?: (item?: CometChat.User | CometChat.GroupMember) => JSX.Element;
  usersRequestBuilder?: CometChat.UsersRequestBuilder;
  disableUsersPresence?: boolean;
  userPresencePlacement?: UserPresencePlacement;
  hideSeparator?: boolean;
  loadingStateView?: JSX.Element;
  onEmpty?: () => void;
  groupMemberRequestBuilder?: CometChat.GroupMembersRequestBuilder;
  loadingIconUrl?: string;
  disableLoadingState?: boolean,
  onError?: () => void;
}

function CometChatUserMemberWrapper(props: IMentionsProps) {
  const {
    userMemberListType = UserMemberListType.users,
    onItemClick,
    listItemView,
    avatarStyle,
    statusIndicatorStyle,
    searchKeyword,
    group,
    subtitleView,
    usersRequestBuilder,
    userPresencePlacement = UserPresencePlacement.bottom,
    loadingStateView,
    onEmpty,
    groupMemberRequestBuilder,
    loadingIconUrl,
    disableLoadingState = false,
    hideSeparator = false,
    onError,
    disableUsersPresence
  } = props;
  const { theme } = useContext(CometChatThemeContext);

  const getUsersStyle = () => {
    return{
      border: '1px solid ' + theme.palette.getAccent300(),
      background: theme.palette.getBackground(),
      minHeight: '45px',
      borderRadius: '12px 12px 12px 12px'
    };
  };

  const getGroupMemebersStyle = () => {
    return {
      border: '1px solid ' + theme.palette.getAccent300(),
      padding: "0px",
      background: theme.palette.getBackground(),
      borderRadius: '12px 12px 12px 12px'
    };
  };



  return (
    <>
      {userMemberListType === UserMemberListType.users && (
        <CometChatUsers
          title=""
          hideSearch={true}
          hideSeparator={hideSeparator}
          showSectionHeader={false}
          onItemClick={onItemClick}
          avatarStyle={avatarStyle}
          statusIndicatorStyle={statusIndicatorStyle}
          searchKeyword={searchKeyword}
          listItemView={listItemView}
          usersRequestBuilder={usersRequestBuilder}
          subtitleView={subtitleView}
          closeButtonIconURL={''}
          loadingStateView={loadingStateView}
          onEmpty={onEmpty}
          loadingIconURL={loadingIconUrl}
          userPresencePlacement={userPresencePlacement}
          disableLoadingState={disableLoadingState}
          disableUsersPresence={disableUsersPresence}
          onError={onError}
          usersStyle={getUsersStyle()}
        />
      )}

      {userMemberListType === UserMemberListType.groupmembers && group && (
        <CometChatGroupMembers
          group={group}
          title=""
          hideSearch={true}
          showBackButton={false}
          hideSeparator={hideSeparator}
          groupMemberRequestBuilder={groupMemberRequestBuilder}
          onItemClick={onItemClick}
          avatarStyle={avatarStyle}
          statusIndicatorStyle={statusIndicatorStyle}
          searchKeyword={searchKeyword}
          listItemView={listItemView}
          subtitleView={subtitleView}
          closeButtonIconURL={''}
          userPresencePlacement={userPresencePlacement}
          onEmpty={onEmpty}
          loadingIconURL={loadingIconUrl}
          tailView={(entity: CometChat.GroupMember) => { return <></> }}
          disableLoadingState={disableLoadingState}
          disableUsersPresence={disableUsersPresence}
          onError={onError}
          groupMembersStyle={getGroupMemebersStyle()}
        />
      )}
    </>
  );
}

export default CometChatUserMemberWrapper;
