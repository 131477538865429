import { ChatConfigurator } from "../../Shared/Framework/ChatConfigurator";
import { AIExtensionDataSource } from "../../Shared/Framework/AIExtensionDataSource";
import { AISmartRepliesDecorator } from "./AISmartRepliesDecorator";
import { AISmartRepliesConfiguration } from "@cometchat/uikit-shared";

export class AISmartRepliesExtension extends AIExtensionDataSource {
  private configuration?: AISmartRepliesConfiguration;

  constructor(configuration?: AISmartRepliesConfiguration) {
    super();
    this.configuration = configuration;
  }

  override addExtension(): void {
    ChatConfigurator.enable((dataSource: any) => new AISmartRepliesDecorator(dataSource, this.configuration));
  }

  override getExtensionId(): string {
    return "smart-replies";
  }
}