import { ChatConfigurator } from "../../Shared/Framework/ChatConfigurator";
import { AIExtensionDataSource } from "../../Shared/Framework/AIExtensionDataSource";
import { AIConversationStarterDecorator } from "./AIConversationStarterDecorator";
import { AIConversationStarterConfiguration } from "@cometchat/uikit-shared";

export class AIConversationStarterExtension extends AIExtensionDataSource {
  private configuration?: AIConversationStarterConfiguration;

  constructor(configuration?: AIConversationStarterConfiguration) {
    super();
    this.configuration = configuration;
  }

  override addExtension(): void {
    ChatConfigurator.enable((dataSource: any) => new AIConversationStarterDecorator(dataSource, this.configuration));
  }

  override getExtensionId(): string {
    return "conversation-starter";
  }
}