/* eslint-disable react-hooks/exhaustive-deps */
import { CometChatTheme } from "@cometchat/uikit-resources";
import { CallLogDetailsConfiguration, CallLogsConfiguration } from "@cometchat/uikit-shared";
import { useEffect } from "react";

function Hooks(
    callLogDetailsConfiguration: CallLogDetailsConfiguration,
    callLogsConfiguration: CallLogsConfiguration,
    setActiveCall: Function,
    isMobileView: boolean,
    theme: CometChatTheme
) {
    useEffect(
        () => {
            callLogDetailsConfiguration.callLogDetailsStyle = { ...callLogDetailsConfiguration.callLogDetailsStyle, border: `1px solid ${theme?.palette?.getAccent100()}` };

            if (isMobileView) {
                const overrideOnBackClick = () => {
                    setActiveCall(null);
                };
                callLogDetailsConfiguration.onBackClick = overrideOnBackClick;
            }
        },
        [callLogDetailsConfiguration, isMobileView, setActiveCall]
    );

    useEffect(
        () => {
            callLogsConfiguration.callLogsStyle = { ...callLogsConfiguration.callLogsStyle, border: `1px solid ${theme?.palette?.getAccent100()}` };
        },
        [callLogsConfiguration]
    );
}

export { Hooks };
