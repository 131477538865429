import { CometChatTheme, fontHelper } from "@cometchat/uikit-resources";

import { AIConversationSummaryStyle } from "@cometchat/uikit-shared";
import { PanelStyle } from "@cometchat/uikit-elements";

export const getContainerStyle = (style?: AIConversationSummaryStyle) => {
  return {
    display: "flex",
    overflow: "hidden",
    width: style?.width || "100%",
    height: style?.height || "100%",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "50px"
  }
};
export const contentContainerStyle: React.CSSProperties = {
  width:"100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "transparent",
  overflow: "hidden",
};

export const getConversationSummaryStyle = (
  theme?: CometChatTheme,
  style?: AIConversationSummaryStyle
) => {
  return {
    replyTextFont:
      style?.textFont ||
      fontHelper((theme as CometChatTheme).typography.caption1),
    replyTextColor: style?.textColor || theme?.palette.getAccent(),
    replyBackground: style?.background || "inherit",
    boxShadow: `0px 0px 1px ${theme?.palette.getAccent600()}`,
    background: style?.background || "inherit",
    width: style?.width || "100%",
    height: style?.height || "100%",
    border: style?.border || "none",
    borderRadius: style?.borderRadius || "8px",
    display: "flex",
    justifyContent: "flex-start",
  };
};

export const getPanelStyle = (theme: CometChatTheme, summaryStyle: AIConversationSummaryStyle): PanelStyle => {
  const style = new PanelStyle({
    width: '100%',
    height: 'fit-content',
    border: `1px solid ${theme.palette.getPrimary()}`,
    borderRadius: '8px',
    background: theme.palette.getBackground(),
    textColor: summaryStyle?.textColor || theme.palette.getAccent600(),
    titleColor: summaryStyle?.titleTextColor || theme.palette.getAccent(),
    textFont: summaryStyle?.textFont || fontHelper(theme.typography.text2),
    titleFont: summaryStyle?.titleTextFont || fontHelper(theme.typography.text1),
  })
  return style;
};