import { CometChatCallDetailsOption, CometChatCallDetailsTemplate, CometChatTheme, DatePatterns, fontHelper, localize } from "@cometchat/uikit-resources";
import { DateStyle, ListItemStyle } from "@cometchat/uikit-elements";
import { convertMinutesToHoursMinutesSeconds, getCallStatusWithType } from "./utils";

import { CallButtonsStyle } from "@cometchat/uikit-shared";
import { CometChatButton } from "../../Shared/Views/CometChatButton";
import { CometChatCallButtons } from "../CometChatCallButtons";
import { CometChatListItem } from "../../Shared/Views/CometChatListItem";
import RightArrowIcon from "../assets/side-arrow.svg";

export class CallingDetailsUtils {

    public static getDefaultCallTemplate(callLog: any, loggedInUser: CometChat.User, theme: CometChatTheme): Array<CometChatCallDetailsTemplate> {
        return [
            this.getPrimaryDetailsTemplate(callLog, loggedInUser, theme),
            this.getSecondaryDetailsTemplate(callLog, loggedInUser, theme),
        ]
    }

    private static getCallButtons(user?: CometChat.User, group?: CometChat.Group) {
        let style: CallButtonsStyle = {
            width: "100%",
            height: "100%",
            border: "none",
            borderRadius: "0",
            background: "transparent",
            buttonPadding: "8px 32px",
            buttonBackground: "RGBA(20, 20, 20, 0.04)",
            buttonBorder: "0 4px",
            buttonBorderRadius: "10px"
        }
        return (
            <CometChatCallButtons
                user={user!}
                group={group!}
                callButtonsStyle={style}
                voiceCallIconText={localize("AUDIO_CALL")}
                videoCallIconText={localize("VIDEO_CALL")}
                key={'callbuttons'}
            />
        )
    }

    public static getPrimaryDetailsTemplate(callLog: any, loggedInUser: CometChat.User, theme: CometChatTheme): CometChatCallDetailsTemplate {
        let template: CometChatCallDetailsTemplate = new CometChatCallDetailsTemplate({
            id: "callControls",
            hideSectionSeparator: true,
            options: (user: CometChat.User | null, group: CometChat.Group | null) => {
                return this.getPrimaryOptions(user ?? undefined, group ?? undefined, callLog ?? undefined, loggedInUser ?? undefined, theme ?? undefined);
            }
        });
        return template;
    }

    public static getSecondaryDetailsTemplate(
        callLog: any,
        loggedInUser: CometChat.User,
        theme: CometChatTheme
      ): CometChatCallDetailsTemplate {
        const template: CometChatCallDetailsTemplate =
          new CometChatCallDetailsTemplate({
            id: "callOptions",
            hideSectionSeparator: true,
            options: () => {
              return this.getSecondaryOptions(
                callLog ?? undefined,
                theme ?? undefined
              );
            },
          });
        return template;
    }

    private static getPrimaryOptions(user?: CometChat.User, group?: CometChat.Group, callLog?: any, loggedInUser?: CometChat.User, theme?: CometChatTheme): CometChatCallDetailsOption[] {
        let options: CometChatCallDetailsOption[] = [];
        if (user) {
            options.push(
                new CometChatCallDetailsOption({
                    id: 'callControls',
                    customView: this.getCallButtons(user, group),
                })
            )
        }

        options.push(
            new CometChatCallDetailsOption({
                id: "callStatus",
                customView: this.generateCallDetailsHTML(callLog, callLog?.getInitiatedAt(), loggedInUser, theme!)
            })
        );

        return options;
    }

    private static getSecondaryOptions(
        callLog?: any,
        theme?: CometChatTheme
    ): CometChatCallDetailsOption[] {
        const options: CometChatCallDetailsOption[] = [];

        const localizedParticipants = "Participants";
        const localizedRecording = "Recordings";
        const localizedHistory = "History";

        if (callLog?.participants && callLog?.participants.length > 0) {
            const count = (callLog?.participants || [])?.length;

            options.push(
                new CometChatCallDetailsOption({
                    id: "participants",
                    customView: this.generateCallOptionsHTML(localizedParticipants, count, theme!),
                })
            );
        }


        if (callLog?.hasRecording) {
            const count = (callLog?.getRecordings() || [])?.length;
            options.push(
                new CometChatCallDetailsOption({
                    id: "recordings",
                    customView: this.generateCallOptionsHTML(localizedRecording, count, theme!),
                })
            );
        }

        options.push(
            new CometChatCallDetailsOption({
                id: "callHistory",
                customView: this.generateCallOptionsHTML(localizedHistory, 0, theme!),
            })
        );

        return options;
    }

    private static generateCallDetailsHTML(callLog: any, initiatedAt: number, loggedInUser: any, theme: CometChatTheme): JSX.Element {
        const listItemStyle = new ListItemStyle({
            height: "auto",
            width: "100%",
            background: theme?.palette?.getAccent100(),
            hoverBackground: theme?.palette?.getAccent100(),
            borderRadius: "8px",
            border: "none",
            padding: "10px",
        });

        const headingDateStyle = new DateStyle({
            textColor: theme?.palette?.getAccent600(),
            textFont: fontHelper(theme?.typography?.text3),
            padding: "0px",
            background: "transparent",
        });

        const dateStyle = new DateStyle({
            textColor: theme?.palette?.getAccent600(),
            textFont: fontHelper(theme?.typography?.subtitle2),
            padding: "0px",
            background: "transparent",
        });

        const status = getCallStatusWithType(callLog, loggedInUser, true);

        const subtitleView = <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
            <cometchat-date
                dateStyle={JSON.stringify(headingDateStyle)}
                pattern={DatePatterns.DayDate}
                timestamp={initiatedAt}
            ></cometchat-date>
            <div style={{display: "flex", gap: "5px"}}>
                <cometchat-date
                    dateStyle={JSON.stringify(dateStyle)}
                    pattern={DatePatterns.time}
                    timestamp={initiatedAt}
                ></cometchat-date>

                <div style={{font:fontHelper(theme?.typography?.text2), color:theme.palette.getAccent()}}>
                    {status}
                </div>
            </div>
        </div>;

        const tailView = <div style={{font: fontHelper(theme?.typography?.text3), color: theme?.palette?.getAccent600(), marginRight: "15px", marginTop: "15px"}}>{convertMinutesToHoursMinutesSeconds(callLog?.getTotalDurationInMinutes())}</div>;

        return (
            <div style={{marginTop: "25px", marginBottom: "25px", width: "100%", paddingLeft: "10px", paddingRight: "10px", boxSizing: "border-box"}}>
                <CometChatListItem
                    key={initiatedAt}
                    hideSeparator={true}
                    listItemStyle={listItemStyle}
                    subtitleView={subtitleView}
                    tailView={tailView}
                />
            </div>
        );
    }

    private static generateCallOptionsHTML(
        title: string,
        count: number,
        theme: CometChatTheme
    ): JSX.Element {
        const buttonStyle = {
            iconHeight: "18px",
            iconWidth: "18px",
            border: "none",
            borderRadius: "0",
            background: "transparent",
            buttonIconTint: theme.palette.getAccent600(),
        };

        const containerStyle = {
            margin: "10px",
            marginBottom: "5px",
            padding: "10px",
            boxSizing: "border-box",
            background: theme?.palette?.getAccent100(),
            borderRadius: "8px", display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
            width: '100%'
        } as React.CSSProperties;

        const titleStyle = {
            font: fontHelper(theme?.typography?.text2),
            color: theme?.palette?.getAccent()
        };

        const buttonContainerStyle = {
            display: "flex",
            alignItems: "center",
            gap: "5px",
            font: fontHelper(theme?.typography?.text3),
            color: theme?.palette?.getAccent600()
        };

        return (
            <div style={containerStyle}>
                <span style={titleStyle}>{title}</span>
                <span style={buttonContainerStyle}>
                    {count > 0 ? count : ""}
                    <CometChatButton iconURL={RightArrowIcon} buttonStyle={buttonStyle} />
                </span>
            </div>
        )
    }
}