import { CometChatTheme, fontHelper } from "@cometchat/uikit-resources";

import { AIConversationStarterStyle } from "@cometchat/uikit-shared";

export const getContainerStyle = (style?: AIConversationStarterStyle) => {
  return {
    display: "flex",
    overflow: "hidden",
    width: style?.width || "100%",
    height: style?.height || "100%",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "50px"
  }
};

export const contentContainerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "transparent",
  overflow: "hidden",
};

export const getConversationStarterStyle = (
  theme?: CometChatTheme,
  style?: AIConversationStarterStyle
) => {
  return {
    replyTextFont:
      style?.textFont ||
      fontHelper((theme as CometChatTheme).typography.caption1),
    replyTextColor: style?.textColor || theme?.palette.getAccent(),
    replyBackground: style?.textBackground || "inherit",
    boxShadow: `0px 0px 1px ${theme?.palette.getAccent600()}`,
    background: style?.background || "inherit",
    width: style?.width || "100%",
    height: style?.height || "100%",
    border: style?.textBorder || "none",
    borderRadius: style?.textBorderRadius || "8px",
    display: "flex",
    justifyContent: "flex-start",
  };
};
