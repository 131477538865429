/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { CometChatUIKit } from "../../Shared/CometChatUIKit/CometChatUIKit";

function Hooks(
    loggedInUser: any,
	setLoggedInUser: Function,
    requestBuilder: any,
    setRequestBuilder: Function,
    getCallList: Function,
    onErrorCallback: Function
) {
    useEffect(
        () => {
            CometChatUIKit.getLoggedinUser().then(
                (user) => {
                    setLoggedInUser(user);
                },
                (error: CometChat.CometChatException) => {
                    onErrorCallback(error);
                }
            );
        },
        [setLoggedInUser, onErrorCallback]
    );
    
    useEffect(()=>{
        if(loggedInUser){
            requestBuilder.current = setRequestBuilder();
            getCallList?.();
        }
    }, [loggedInUser]);
}

export { Hooks };