import { AISmartRepliesStyle } from "@cometchat/uikit-shared";
import { CometChatTheme, fontHelper } from "@cometchat/uikit-resources";

export const getContainerStyle = (style?: AISmartRepliesStyle, theme?: CometChatTheme) => {
  return {
    display: "flex",
    width: style?.width || "100%",
    height: style?.height || "100%",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "50px",
    background: style?.background || theme?.palette.getBackground(),
    borderRadius: style?.borderRadius || "8px",
  }
};

export const contentContainerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "transparent",
  overflow: "auto",
  height: "100%",
};

export const getSmartReplyStyle = (
  theme?: CometChatTheme,
  style?: AISmartRepliesStyle
) => {
  return {
    replyTextFont: style?.textFont || fontHelper((theme as CometChatTheme).typography.caption1),
    replyTextColor: style?.textColor || theme?.palette.getAccent(),
    replyBackground: style?.textBackground || "inherit",
    boxShadow: `0px 0px 1px ${theme?.palette.getAccent600()}`,
    background: style?.background || "inherit",
    border: style?.textBorder || "none",
    borderRadius: style?.textBorderRadius || "8px",
    display: "flex",
    justifyContent: "flex-start",
  };
};

export function getBackButtonStyle(style?: AISmartRepliesStyle, theme?: CometChatTheme): any {
  return {
    height: "24px",
    width: "24px",
    buttonIconTint: style?.backIconTint || theme?.palette.getPrimary() || "",
    border: "none",
    borderRadius: "8px",
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
  };
}

export function getSmartRepliesContainerStyle(): any {
  return {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    boxSizing: "border-box",
  };
}

export function getSmartRepliesTitleStyle(style?: AISmartRepliesStyle, theme?: CometChatTheme): any {
  return {
    display: "flex", 
    justifyContent: "space-between",
    alignItems: "center",
    font: style?.titleFont || fontHelper(theme!.typography.text2),
    color: style?.titleColor || theme?.palette.getAccent(),
  };
}