import {
  AIAssistBotConfiguration,
  AIAssistBotStyle,
} from "@cometchat/uikit-shared";
import {
  ActionSheetStyle,
  AvatarStyle,
  MessageInputStyle,
  ReceiptStyle,
} from "@cometchat/uikit-elements";
import { CometChatTheme, MessageBubbleAlignment, fontHelper } from "@cometchat/uikit-resources";
import { getBubbleAlignment } from "./utils";

export const getContainerStyle = (
  style?: AIAssistBotStyle,
  theme?: CometChatTheme
) => {
  return {
    display: "flex",
    width: style?.width || "100%",
    height: style?.height || "100%",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "50px",
    background: style?.background || theme?.palette?.getBackground(),
    borderRadius: style?.borderRadius || "8px",
  } as React.CSSProperties;
};

export const getBotsContainerStyle = () => {
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    boxSizing: "border-box",
  } as React.CSSProperties;
};

export const getBotTitleStyle = (
  style?: AIAssistBotStyle,
  theme?: CometChatTheme
) => {
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    font:
      style?.optionTitleFont || (theme && fontHelper(theme?.typography?.text2)),
    color: style?.optionTitleColor || theme?.palette?.getAccent(),
  } as React.CSSProperties;
};

export const getBackButtonStyle = (
  style?: AIAssistBotStyle,
  theme?: CometChatTheme
) => {
  return {
    height: "24px",
    width: "24px",
    buttonIconTint: style?.backIconTint || theme?.palette.getPrimary() || "",
    border: "none",
    borderRadius: "8px",
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
  };
};

export const getOptionsContainerStyle = () => {
  return {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
  } as React.CSSProperties;
};

export const getOptionStyle = (
  style?: AIAssistBotStyle,
  theme?: CometChatTheme
) => {
  return new ActionSheetStyle({
    ActionSheetSeparatorTint: theme?.palette?.getAccent400(),
    background: style?.buttonBackground || theme?.palette?.getBackground(),
    titleColor: style?.buttonTextColor || theme?.palette?.getAccent(),
    titleFont:
      style?.buttonTextFont || (theme && fontHelper(theme?.typography?.text2)),
    listItemBackground: "transparent",
    height: "100%",
    width: "100%",
    border: "none",
  });
};

export const getBotBackgroundStyle = (theme?: CometChatTheme) => {
  return {
    display: "flex",
    justifyContent: "flex-end",
    height: "100%",
    width: "100%",
    background: theme?.palette?.getAccent600(),
  } as React.CSSProperties;
};

export const getBotChatContainerStyle = (
  style?: AIAssistBotStyle,
  theme?: CometChatTheme
) => {
  return {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    minWidth: "350px",
    maxWidth: "100%",
    height: "100%",
    overflow: "hidden",
    boxSizing: "border-box",
    background: style?.background || theme?.palette.getBackground(),
  } as React.CSSProperties;
};

export const getBotChatHeaderStyle = (theme?: CometChatTheme) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "40px",
    padding: "10px",
    boxSizing: "border-box",
    background: theme?.palette.getAccent200(),
    marginBottom: "10px"
  } as React.CSSProperties;
};

export const getAvatarStyle = (style: AvatarStyle) => {
  if (style) {
    return style;
  } else {
    return new AvatarStyle({
      borderRadius: "16px",
      width: "28px",
      height: "28px",
    });
  }
};

export const getBotHeaderTitleStyle = (
  style: AIAssistBotStyle = {},
  theme: CometChatTheme
) => {
  return {
    font: style.titleFont || fontHelper(theme.typography.subtitle1),
    color: style.titleColor || theme.palette.getAccent(),
  } as React.CSSProperties;
};

export const getBotHeaderSubtitleStyle = (
  style: AIAssistBotStyle = {},
  theme: CometChatTheme
) => {
  return {
    font: style.subtitleFont || fontHelper(theme.typography.caption2),
    color: style.subtitleColor || theme.palette.getAccent500(),
  } as React.CSSProperties;
};

export const getCloseButtonStyle = (
  style?: AIAssistBotStyle,
  theme?: CometChatTheme
) => {
  return {
    height: "24px",
    width: "24px",
    buttonIconTint: style?.closeIconTint || theme?.palette.getPrimary() || "",
    border: "none",
    borderRadius: "8px",
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
  } as React.CSSProperties;
};

export const getMessageBubbleStyle = (
  message: CometChat.TextMessage,
  theme: CometChatTheme,
  sender: CometChat.User,
  configuration: AIAssistBotConfiguration
) => {
  // @ts-ignore
  if (message.sender.getUid() === sender.getUid()) {
    const style = configuration?.senderMessageBubbleStyle || {};
    return {
      background: style.background || theme.palette.getPrimary(),
      borderRadius: style.borderRadius || "12px",
      border: style.border || "none",
    };
  } else {
    const style = configuration?.botMessageBubbleStyle || {};
    return {
      background: style.background || theme.palette.getAccent100(),
      borderRadius: style.borderRadius || "12px",
      border: style.border || "none",
    };
  }
};

export const getMessageListFooterStyle = () => {
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "40px",
    padding: "5px",
    boxSizing: "border-box",
  } as React.CSSProperties;   
}

export const getMessageInputStyle = (
  style: MessageInputStyle,
  theme: CometChatTheme
): MessageInputStyle => {
  if (style) {
    return style;
  } else {
    return new MessageInputStyle({
      height: "40px",
      width: "100%",
      maxHeight: "40px",
      border: `1px solid ${theme.palette.getAccent100()}`,
      borderRadius: "12px",
      background: theme.palette.getAccent50(),
      textFont: fontHelper(theme.typography.text2),
      textColor: theme.palette.getAccent(),
      dividerColor: theme.palette.getAccent100(),
      inputBorder: "none",
      inputBorderRadius: "0px",
      inputBackground: "transparent",
    });
  }
};

export const getSendButtonStyle = (
  style?: AIAssistBotStyle,
  theme?: CometChatTheme
) => {
  return {
    height: "24px",
    width: "24px",
    buttonIconTint: style?.sendIconTint || theme?.palette.getPrimary() || "",
    border: "none",
    borderRadius: "8px",
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
  } as React.CSSProperties;
};

export const getMessageBubbleDateStyle = (theme: CometChatTheme) => {
  return {
    textColor: theme?.palette?.getAccent600(),
    textFont: fontHelper(theme?.typography?.caption2),
    padding: "0px",
  } as React.CSSProperties;
};

export const getMessageReceiptStyle = (
  style: AIAssistBotStyle,
  theme: CometChatTheme
) => {
  return new ReceiptStyle({
    waitIconTint: style?.loadingIconTint || theme?.palette?.getAccent700(),
    errorIconTint: style?.errorIconTint || theme?.palette?.getError(),
  });
};

export const getMessageBubbleContainerStyle = (message: CometChat.TextMessage, sender: CometChat.User) => {
  const bubbleAlignment = getBubbleAlignment(message, sender);
  return {
    flex: "1 1 0",
    display: "flex",
    flexDirection: "column",
    borderRadius: "inherit",
    justifyContent: bubbleAlignment === MessageBubbleAlignment.left ? "flex-start" : "flex-end",
    alignItems: bubbleAlignment === MessageBubbleAlignment.left ? "flex-start" : "flex-end",
    position: "relative",
  } as React.CSSProperties;
};
