
import { CometChatTheme, fontHelper } from "@cometchat/uikit-resources";
import { CSSProperties } from "react";
import { ReceiptStyle, DateStyle, BackdropStyle } from "@cometchat/uikit-elements";
import { BaseStyle, MessageInformationStyle } from "@cometchat/uikit-shared";

type ButtonStyle = CSSProperties & { buttonIconTint?: string };
type WrapperStyle = CSSProperties & { position?: string };

export function closeBtnStyle(theme: CometChatTheme): ButtonStyle {
    return {
        height: "24px",
        width: "24px",
        border: "none",
        borderRadius: "0",
        buttonIconTint: theme.palette.getPrimary(),
        background: "transparent",
        position: "absolute",
        top: "20px",
        right: "16px"
    };
}

export const dividerStyle = (theme: CometChatTheme) => {
    return {
        height: "1px",
        width: "100%",
        background: theme.palette.getAccent100()
    }
}

export function receiptStyle(theme: CometChatTheme): ReceiptStyle {
    return new ReceiptStyle({
        deliveredIconTint: theme.palette.getAccent600(),
        readIconTint: theme.palette.getPrimary(),
        errorIconTint: theme.palette.getError(),
        height: "20px",
        width: "20px"
    });
}

export const MessageDateStyle = (dateSeparatorStyle: DateStyle | undefined, theme: CometChatTheme) => {
    let tempDateSeparatorStyle: DateStyle | undefined = dateSeparatorStyle;
    let defaultDateStyle = new DateStyle({
        textFont: fontHelper(theme.typography.caption2),
        textColor: theme.palette.getAccent600(),
        background: theme.palette.getAccent100(),
        height: "100%",
        width: "100%",
        border: `1px solid ${theme.palette.getAccent100()}`,
        borderRadius: "8px",
    })

    tempDateSeparatorStyle = { ...defaultDateStyle, ...tempDateSeparatorStyle };

    tempDateSeparatorStyle.background = tempDateSeparatorStyle.background || theme.palette.getAccent600()

    return tempDateSeparatorStyle;
}

export const defaultDateSeparatorStyle = {
    background: "transparent",
    height: "",
    width: "",
    border: "none",
    borderRadius: "none",
}

export const dateInfoStyle = {
    marginLeft: "auto"
}
export const receiptWrapperStyle = {
    display: "flex"
}
export const receiptSubtitleWrapperStyle = {
    width: "100%"
}
export const messageInfoStyle = (theme: CometChatTheme) => {
    return {
        width: "360px", height: "620px", margin: "0 auto", background: theme.palette.getBackground(), borderRadius: "12px"
    }
}

export const messageInfoHeaderStyle = (theme: CometChatTheme): WrapperStyle => {
    return {
        textAlign: "center",
        position: "relative",
        font: fontHelper(theme.typography.title1),
        color: theme.palette.getAccent(),
        padding: "19px 0px"

    }
}
export const getMessageInfoStyle = (theme: CometChatTheme, messageInfo?: MessageInformationStyle): CSSProperties => {
    let defaultStyle = {
        width: "360px",
        height: "620px",
        margin: "0 auto",
        background: theme.palette.getBackground(),
        borderRadius: "12px",
        overflow: "hidden",
        boxSizing: "border-box" as any
    }
    return { ...defaultStyle, ...messageInfo }
}

export const getReceiptTextStyle = (theme: CometChatTheme): CSSProperties => {
    return {
        marginTop: "8px",
        marginBottom: "8px",
        marginLeft: "10px",
        fontSize: fontHelper(theme.typography.subtitle2),
        fontWeight: "500",
        color: theme.palette.getAccent600(),
        display: "flex"

    }
}


export const getMessageTextStyle = (theme: CometChatTheme): CSSProperties => {
    return {
        marginBottom: "8px",
        marginLeft: "10px",
        fontSize: fontHelper(theme.typography.subtitle2),
        fontWeight: "500",
        color: theme.palette.getAccent600(),
        display: "flex"
    }
}


export const getParentBubbleStyle = (alignBubble?: boolean): CSSProperties => {
    return {
        height: "fit-content",
        maxHeight: "182px",
        overflow: "hidden auto",
        boxSizing: "border-box",
        padding: "2px 8px",
        justifyContent: "flex-end",
        display: alignBubble ? "flex" : "inherit",
        paddingRight: alignBubble ? "16px" : "8px"
    }
}

export function getBackdropStyle(backdropStyleObject : BaseStyle) : BackdropStyle {
    return {
        background: 'rgba(0, 0, 0, 0.5)',
        position: 'absolute',
        width: backdropStyleObject?.width,
        height: backdropStyleObject?.height,
        ...backdropStyleObject,
    };
}